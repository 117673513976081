import React, { useState } from "react";
import { Modal, Button, DatePicker, Select, Form, Input } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "bootstrap/dist/css/bootstrap.min.css";

import BackgroundeventImg from "../assets/images/noactivebg.png";
import EmojieventImg from "../assets/images/noactiveimg.png";

function NoactiveIndex() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModalOpensecond, setIsModalOpen1] = useState(false);

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleOk1 = () => {
    setIsModalOpen1(false);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Open Modal
      </Button>
      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={749}
        footer={false}
        className="noevent-model"
      >
        <div className="no-active_event">
          <div className="event-backgg">
            <img src={BackgroundeventImg} />
            <div className="event-happy-emoji">
              <img src={EmojieventImg} />
            </div>
            <div className="noevent-text">
              <h2>{("No Active Events!")}</h2>
              <p>{("Please Create your Own Event.")}</p>
              <Button type="primary">{("Primary Button")}</Button>
            </div>
          </div>
        </div>
      </Modal>

      <Button type="primary" onClick={showModal1}>
        Open Modal
      </Button>
      <Modal
        title="Basic Modal"
        open={isModalOpensecond}
        onOk={handleOk1}
        onCancel={handleCancel1}
        width={749}
        footer={false}
        className="noevent-model"
      >
        <div className="no-active_event">
          <div className="event-backgg-header">
            <h2>Enter Your Details for Personalized Experience</h2>
          </div>
          <div className="noevent-text">
            <Form className="noevent-maain-model">
              <Form.Item>
                <Select
                  defaultValue="lucy"
                  options={[
                    { value: "jack", label: "Jack" },
                    { value: "lucy", label: "Lucy" },
                    { value: "Yiminghe", label: "yiminghe" },
                    { value: "disabled", label: "Disabled" },
                  ]}
                />
              </Form.Item>
              <Form.Item>
                <DatePicker picker="week" />
              </Form.Item>
              <Form.Item>
                <Input />
              </Form.Item>

              <Form.Item>
                <Input />
              </Form.Item>
              <Button type="primary">Get Started</Button>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default NoactiveIndex;
