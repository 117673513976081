import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext"; // Assuming you have a UserContext to get user data
import apiPath from "../constants/apiPath";
import useRequest from "../hooks/useRequest";

const Commonfunction = () => {
  const { userProfile } = useAuthContext();
  const [user, setUser] = useState();
  const { request } = useRequest();
  const navigate = useNavigate();

  const updateProviderViewCount = (id, serviceId) => {
    let vendor_id = id ? id : "";
    const service_id = serviceId ? serviceId : ""
    const payload = {
      service_id : service_id
    }
    request({
      url: `${apiPath.updateViewCount}/${vendor_id}`, // Pass vendor_id in the URL
      method: "POST",
      data : payload,
      onSuccess: (data) => {
        if (data.status) {
          console.log(data?.message, "updateviewCount");
          navigate(`/provider/${vendor_id}`, serviceId ? { state: { serviceId } } : undefined);
        }
      },
      onError: (err) => {
        // ShowToast(err, Severty.ERROR);
        console.log(err);
      },
    });
  };

  return {
    updateProviderViewCount,
  };
};

export default Commonfunction;
