import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel3";
import { Row, Col } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import { Accordion } from "react-bootstrap";
import SearchiconImg from "../../assets/images/ion_search-outline.png";
import QuotationlogoImg from "../../assets/images/quotation-page.png";
import MessagechatImg from "../../assets/images/fluent_chat-32-regular.png";
import BookmarkImg from "../../assets/images/solar_bookmark-outline.png";
import PdflogoImg from "../../assets/images/pdf.png";
import { useNavigate, useParams } from "react-router";
import InviteFriends from "../../components/InviteFriends";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import Loader from "../../components/Loader";
import { Image, Input } from "antd";

import useDebounce from "../../hooks/useDebounce";
import lang from "../../helper/langHelper";
import notfound from "../../assets/images/not-found-img.png";

import AddBudget from "../Provider/AddBudget";
import { useAppContext } from "../../context/AppContext";

function AddNewServices() {
  const navigate = useNavigate();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [services, setServices] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const {language} = useAppContext()
  const params = useParams();
  const [showBudget, setShowBudget] = useState(false);
  const [selected, setSelected] = useState({
    attributes: undefined,
    service_id: undefined,
    vendor_id: undefined,
    price: undefined,
    addBudgetType: "serviceBudget",
  });

  const getServices = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.categoryServices}/${id}?search=${debouncedSearchText}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        console.log(data, "data......");
        setServices(data?.docs);
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getServices(params.categoryId);
  }, [debouncedSearchText, refresh]);

  return (
    <Main>
      <>
        <section>
          <div className="container">
            <div className="main-quotation-detailss main-quotation-detailss-main-newww">
              <div className="pageHeadingSearch">
                <Input.Search
                  className="searchInput"
                  placeholder={lang("Search by service name...")}
                  onChange={(e) => setSearchText(e.target.value)}
                  allowClear
                />
              </div>
              <div>
                <Row gutter={[24, 24]} className="pt-3">
                  {loading ? (
                    <Loader />
                  ) : services?.length && services.length > 0 ? (
                    services.map((service) =>
                      (
                        
                          <Col span={24} md={24} lg={12} key={service._id}>
                            <div className="venue-lalsion" style={{cursor : "pointer"}}>
                              <div
                                className="venue-lalsion-1"
                                onClick={() => {
                                  setShowBudget(true);
                                  setSelected({
                                    attributes: service?.attribute_id,
                                    service_id: service?._id,
                                    vendor_id: service?.provider_id?._id,
                                    price: service?.discount_price ? service?.discount_price : service?.original_price || 0 ,
                                    event_id: params?.eventId,
                                    addBudgetType: "serviceBudget",
                                    showEvent: false,
                                    category_id : params.categoryId
                                  });
                                }}
                              >
                                <div className="venue-img">
                                  <div className="venue-main-immg">
                                    <img
                                      src={service?.cover_image ? service?.cover_image : service?.service_id?.image || notfound }
                                      alt=""/>
                                  </div>
                                  <div className="venie-m-txt">
                                    <h2>
                                    {language !== "en" && language !== null
                                        ? service?.[
                                        `${language}_name`
                                        ] ?? service?.name
                                        : service?.name}</h2>
                                    <h3>
                                      <span>{lang("Vendor")}:</span>{" "}
                                     
                                      {language !== "en" && language !== null
                                        ? service?.provider_id?.[
                                        `${language}_name`
                                        ] ?? service?.provider_id?.name
                                        : service?.provider_id?.name}
                                    </h3>
                                  </div>
                                </div>
                                <div className="venue-cost">
                                  <h3>Actual cost:</h3>
                                  <h3 className="jod-price-disc.">
                                  JOD{" "}
                                  {service?.discount_price ?? service?.discount_price}{" "}
                                  {service?.original_price ? (service?.discount_price ? <span>{service?.original_price} </span> : service?.original_price 
                                  ) : ( "")}
                                </h3>
                                </div>
                              </div>
                              <div className="vensu-teext">
                                <h4>
                                  {language !== "en" && language !== null
                                        ? service?.provider_id?.[
                                        `${language}_description`
                                        ] ?? service?.provider_id?.description
                                        : service?.provider_id?.description}
                                </h4>
                              </div>
                            </div>
                          </Col>
                        )
                      
                    )
                  ) : (
                    <Col span={24}>
                      <div className="venue-lalsion">
                        <div className="venue-lalsion-1">
                          <h4>{lang("No services available on this category")}</h4>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </div>
        </section>

        <InviteFriends />
        {showBudget && (
          <AddBudget
            show={showBudget}
            hide={() => {
              setShowBudget((prev) => !prev);
              setSelected("");
            }}
            selected={selected}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}
      </>
    </Main>
  );
}

export default AddNewServices;
