import { Col, Row, Form, Modal, Input, Button } from "antd";
import Stories from "react-insta-stories";
import { formatStringDate, formatTimeAgo } from "../helper/functions";
import { useAuthContext } from "../context/AuthContext";
import lang from "../helper/langHelper";
import { useState } from "react";
import {
  HeartOutlined,
  SendOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import ChatRepository from "../pages/Message/ChatRepository";
import { serverTimestamp } from "firebase/firestore";
import { Severty, ShowToast } from "../helper/toast";
import Prouser from "../assets/images/user.png";

const ReadStory = ({ show, hide, refresh, data, providerData }) => {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const chatRepo = ChatRepository();
  const supportedVideoExtensions = [".mp4", ".ogg", ".webm", ".mpeg"];

  let isVideo = supportedVideoExtensions.some((ext) =>
    data?.image?.toLowerCase()?.endsWith(ext)
  );
  const stories =  [{url: data?.image,
    duration: 5000,
    type: isVideo ? "video" : "image",
    header: {
      heading: data?.title,
      subheading: `Posted ${formatTimeAgo(data?.created_at)}`,
      profileImage: providerData?.logo ? providerData?.logo : providerData?.selected_category_id?.image ? providerData?.selected_category_id?.image :  Prouser,
    },
}];

  const sendMessage = () => {
    if (msg && msg?.trim()) {
      let userId = JSON.parse(localStorage.getItem("userProfile"))?._id;

      const groupdId = userId + "_" + providerData?._id;

      let oppositeUserData = {
        image: providerData?.logo,
        mobileNumber: providerData?.mobile_number,
        name: providerData?.name,
        unseenCount: 0,
      };
      let quotationData = {
        title: "",
        image: "",
        budget: "",
        description: "",
        isQuotation: false,
      };

      chatRepo.createGroup(
        groupdId,
        userId,
        providerData?._id,
        oppositeUserData,
        quotationData
      );

      const message = {
        groupId: groupdId,
        message: msg,
        sendTime: serverTimestamp(),
        senderId: userId,
        fromStory: true,
        storyUrl: data?.image,
        type: "text",
      };

      console.log(message, " : : message");
      chatRepo.updateSeenMessages(groupdId, userId, msg);
      chatRepo.sendMessage(message);
      ShowToast("Message Sent!", Severty.SUCCESS);
      setMsg("");
    }
  };

  return (
    <Modal
      open={show}
      cancelText={<ArrowRightOutlined className="main-back--button-csss" />}
      width={360}
      onCancel={hide}
      centered
      okButtonProps={{
        form: "story",
        htmlType: "submit",
        loading: loading,
      }}
      className="full-width-modal tab_modal deleteWarningModal eleteWarningModal-hhht5657"
    >
      <Form className="" id="story" layout="vertical">
        <Row gutter={[16, 0]} className="stories-container">
          <Col span={24} sm={24} className="mx-auto p-0">
            <Stories
              stories={stories.map((story) => ({
                ...story,
                content: () =>
                  story.type === "video" ? (
                    <video
                      src={story.url}
                      controls
                      autoPlay
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <>
                    <img
                      src={story.url}
                      alt=""
                      style={{ width: "100%", height: "100%", maxWidth: "100%", }}
                    />
                    <div
                    style={{
                      position: "absolute",
                      top: "20px",
                      left: "20px",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      border: "2px solid white",
                    }}
                  >
                    <img
                      src={story.header.profileImage}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  </>
                  ),
              }))}
              defaultInterval={3000}
              width={"100%"}
              className={("stories", "new-class")}
            />
            <div className="message-container">
              <Input
                placeholder="Send Message"
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                className="message-input"
              />

              <Button
                shape="circle"
                onClick={() => {
                  sendMessage();
                }}
                icon={<SendOutlined />}
                className="icon-button"
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ReadStory;
