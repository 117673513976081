import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Col,
  Row,
  InputNumber,
  Radio,
  Checkbox,
  Divider,
} from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "bootstrap/dist/css/bootstrap.min.css";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";

import BackgroundeventImg from "../../assets/images/noactivebg.png";
import EmojieventImg from "../../assets/images/noactiveimg.png";
import { useNavigate } from "react-router";
import Loader from "../../components/Loader";
import AddEvent from "../Event/AddEvent";
import { useAppContext } from "../../context/AppContext";

function PackageAddBudget({ hide, show, refresh, selected ,showNewEvent}) {
  const [events, setEvents] = useState([]);
  const [form] = Form.useForm();
  const { request } = useRequest();
  const {language} = useAppContext()
 
  const [loading, setLoading] = useState(false);
  console.log(selected,"selected")
 
  const navigate = useNavigate();

  const getEventType = () => {
    setLoading(true);
    request({
      url: apiPath.common.userEvent,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (status) {
          setEvents(data);
        }
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  const onSubmit = (values) => {
    setLoading(true);
    const { event_id } = values;
  

    const payload = {
 
      event_id: selected.event_id ? selected.event_id : event_id,
      package_id: selected?.package_id ? selected?.package_id : null,
      price : selected?.price ? selected?.price :null,
      category_id : selected?.category_id
     
    };

    //  return  console.log(payload,"payload")

    request({
      url: apiPath.addPkgBudget,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {        
          ShowToast(data.message, Severty.SUCCESS);
          if (selected.event_id) {
            navigate(-1);
            hide();
          } else {
            hide();
          }
          form.resetFields();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        setLoading(false);
        ShowToast(err?.response?.data?.errors?.[0]?.message, Severty.ERROR);
        console.log(err);
      },
    });
  };

  useEffect(() => {
    console.log(selected, "selected......");
    setLoading(true);
    getEventType();
  }, [selected]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : events?.length && events?.length > 0 ? (
        <Modal
          open={show}
          onCancel={hide}
          title="Basic Modal"
          width={600}
          className="noevent-model"
          okText={lang("Submit")}
          cancelText={lang("Back")}
          okButtonProps={{
            form: "create",
            htmlType: "submit",
            loading: loading,
            className: "submit-button", // Custom class for Submit button
          }}
          cancelButtonProps={{
            className: "back-button", // Custom class for Back button
          }}
        >
          <div className="no-active_event">
            <div className="event-backgg-header">
              <h2>{lang("Are You Sure you want to  this Package in budget")}</h2>
            </div>
            <div className="noevent-text">
              <Form
                className="noevent-maain-model"
                id="create"
                form={form}
                onFinish={onSubmit}
                layout="vertical"
              >
                <Row gutter={[24, 0]}>
                  {selected.showEvent ? (
                    <>
                    <Col xl={24} md={24} span={24}>
                      <Form.Item
                        className="filtter-select"
                        name="event_id"
                        label={lang("Add my event")}
                        rules={[
                          {
                            required: true,
                            message: lang("Please select a event!"),
                          },
                        ]}
                      >
                        <Select
                          className="form-select"
                          filterOption={(input, option) =>
                            option?.label
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          }
                          placeholder={lang("Select Event Type")}
                          showSearch
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                        >
                          {events?.map((item) => (
                            <Select.Option
                              key={item._id}
                              label={item.name}
                              value={item._id}
                            >
                              {language !== "en" && language !== null
                        ? item?.[`${language}_name`] ?? item?.name
                        : item?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                     <Divider>{lang("OR")}</Divider>
                   <div className="new-event-buttonmain-button9966">  <Button className="new-event-button" onClick={()=>showNewEvent()}>{lang("Create a New Event")}</Button></div>
                     </>
                  ) : (
                    ""
                  )}

                  
                </Row>
              </Form>
            </div>
          </div>
        </Modal>
      ) : (
        // <Modal
        //   title="Basic Modal"
        //   open={show}
        //   onCancel={hide}
        //   width={700}
        //   footer={false}
        //   className="noevent-model"
        // >
        //   <div className="no-active_event no-active_event-main99">
        //     <div className="event-backgg">
        //       <img src={BackgroundeventImg} />
        //       <div className="event-happy-emoji">
        //         <img src={EmojieventImg} />
        //       </div>
        //       <div className="noevent-text">
        //         <h2> No Active Events!</h2>
        //         <p> Please Create your Own Event.</p>
        //         <Button type="primary" onClick={()=>showNewEvent()}>
        //           Create Event
        //         </Button>
        //       </div>
        //     </div>
        //   </div>
        // </Modal>
        <Modal
        title="Basic Modal"
        open={show}
        onCancel={hide}
        width={700}
        footer={false}
        className="noevent-model"
      >
        <div className="no-active_event no-active_event-main99">
          <div className="event-backgg">
            <div className="no-eve-modal">
            <img src={BackgroundeventImg} />
            </div>
            <div className="event-happy-emoji emogi-event">
              <img src={EmojieventImg} />
            </div>
            <div className="noevent-text noheadding">
              <h2>{lang("No Active Events!")}</h2>
              <p>{lang("Please Create your Own Event.")}</p>
              <Button className="new-event-button" onClick={()=>showNewEvent()}>
                {lang("Create Event")}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      )}

      
    </>
  );
}

export default PackageAddBudget;
