import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { uploadFile } from "react-s3";
import Prouser from "../../assets/images/user.png";
import AccountSideNav from "../../components/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";
import { dateString } from "../../helper/functions";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import InvitefrndImg from "../../assets/images/invite-frnd.png";
import { Accordion } from "react-bootstrap";
import Loader from "../../components/Loader";
import lang from "../../helper/langHelper";

window.Buffer = window.Buffer || require("buffer").Buffer;

function Privacy() {
  const { request } = useRequest();

  const { isMobile ,language } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [privacy, setPrivacy] = useState([]);

 
  const fetchData = () => {
    setLoading(true)
    request({
      url: apiPath.baseURL + "/app/content/privacy-policy",
      method: "GET",
      onSuccess: (data) => {
        console.log("data", data);
        if (!data.status) return ShowToast(data?.message, Severty.ERROR);
        setPrivacy(data.docs?.[0]);
          const list = language !== "en" && language !== null   ? data?.docs?.[0]?.[`${language}_faq`] :   data?.docs?.[0].faq  
        setData(list)
        setLoading(false)
      },
      onError: (err) => {
        setLoading(false)
        console.log(err);
        ShowToast(err, Severty.ERROR);
      },
    });
  };
  

useEffect(()=>{
  setLoading(true)
  fetchData()
},[])

  return (
    <>
      <section className="main">
     
        <div className="account-outers">
          <Row>
            {!isMobile ? <AccountSideNav /> : <BackArrow />}
            <Col span={24} lg={18} xl={15} className="mx-auto  amt-auto">
              <div className="privecy-main-discription-1">
                <h3>{lang("Privacy Policy")}</h3>
                <div className="prvecy-main-discription">
                  {loading ? <Loader/> :
                  <>
                    <div
                    className="terms-left-outer"
                    dangerouslySetInnerHTML={{
                      __html: privacy ? language !== "en" && language !== null
                      ? privacy?.[`${language}_description`] ?? privacy?.description
                      : privacy?.description : "",
                    }}
                  /> 
                 { data?.map((item)=>(
                    <>              
                    <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="freq-ask-neww">
                       
                        { language !== "en" && language !== null
                                ? item?.[`${language}_question`] ?? item?.question
                                : item?.question }
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        { language !== "en" && language !== null
                                ? item?.[`${language}_answer`] ?? item?.answer
                                : item?.answer }</p>
                      </Accordion.Body>
                    </Accordion.Item>
                     </Accordion>
                     </>
                  ))}</> }

               
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
}

export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar">
      <Link onClick={() => navigate(-1)} className="sidebar-btn">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};

export default Privacy;
