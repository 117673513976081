import {
  ArrowLeftOutlined,
  HeartFilled,
  HeartOutlined,
  LikeOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  message,
  Tabs,
} from "antd";
import axios from "axios";
import moment from "moment";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { uploadFile } from "react-s3";
import Prouser from "../../assets/images/user.png";
import AccountSideNav from "../../components/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";
import { dateString } from "../../helper/functions";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import GreyheartImg from "../../assets/images/grey-fav.png";
import FavrateServesImg from "../../assets/images/favrate-seves.png";
import { TabPane } from "react-bootstrap";
import Star4Img from "../../assets/images/fvrt-main.png";
import NofavService from "../../assets/images/no-data.png"; 
import Nodata from "../../assets/images/no-data.png";
import notfound from "../../assets/images/not_found.png";
import Loader from "../../components/Loader";
import lang from "../../helper/langHelper";

window.Buffer = window.Buffer || require("buffer").Buffer;

function Favourite() {
  const { request } = useRequest();
  const { isMobile, language } = useAppContext();
  const { setIsLoggedIn, setUserProfile, userProfile } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [services, setServices] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [packages, setPackages] = useState([]);

  const navigate = useNavigate();

  const getWishlist = () => {
    setLoading(true);
    request({
      url: apiPath.baseURL + "/app/wishlist",
      method: "GET",
      onSuccess: (data) => {
        console.log("data", data);
        if (!data.status) return;
        setProviders(data.providers);
        setServices(data.services);
        setPackages(data.packages);
        setLoading(false);
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
        ShowToast(err, Severty.ERROR);
      },
    });
  };

  const addToFavorite = ({ id, type }) => {
    setLoading(true);
    console.log(type, "type...");
    const payload = { type: type };
    if (type === "Provider") payload.vendor_id = id;
    if (type === "Service")
      payload.service = {
        service_id: id?.service_id?._id,
        provider_id: id?.provider_id?._id,
      };
    if (type === "Packages")
      payload.packages = {
        packages_id: id?.packages_id?._id,
        provider_id: id?.provider_id?._id,
      };
    request({
      url: apiPath.baseURL + "/app/wishlist",
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (!data.status) return;
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (err) => {
        console.log(err);
        ShowToast(err, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getWishlist();
  }, [refresh]);

  const handleSrcNavigate = (providerId,serviceId) => {
    navigate(`/provider/${providerId}`, serviceId ? { state: { serviceId } } : undefined);
  }

  return (
    <>
      <section className="main">
        <div className="account-outers">
          <Row>
            {!isMobile ? <AccountSideNav /> : <BackArrow />}
            <Col span={24} lg={18} xl={15} className="mx-auto  amt-auto">
              <Tabs defaultActiveKey="1" className="message-maaxx">
                <TabPane tab={lang("Services")} key="1" className="budget-main">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="faverate-servicess">
                      {services.length ? (
                        services?.map((item) => (
                          <div className="mian-fvrt-services" 
                          style={{cursor : "pointer"}}
                          onClick={() =>
                          
                            handleSrcNavigate(item?.service?.provider_id?._id,item?.service?.service_id?._id)
                          }
                          >
                            <div className="mian-fvrt-ser-1">
                              <img
                                src={
                                 item?.service?.service_id?.cover_image ? item?.service?.service_id?.cover_image :
                                   ( item?.service?.service_id?.service_id?.image ? item?.service?.service_id?.service_id?.image :
                                     item?.service?.provider_id?.logo ?? notfound)
                                }
                                alt=""
                              />
                            </div>
                            <div className="mian-fvrt-ser-2">
                              <div
                              
                              >
                                <h3>
                                  { language !== "en" && language !== null
                                    ? item?.service?.service_id?.service_id?.[ `${language}_name`] ?? item?.service?.service_id?.service_id?.name
                                    : item?.service?.service_id?.service_id?.name} 
                                </h3>
                                <h5>
                                          JOD{" "}
                                          {item?.service?.service_id?.discount_price ??
                                            item?.service?.service_id?.discount_price}{" "}
                                          {item?.service?.service_id?.original_price ? (
                                            item?.service?.service_id?.discount_price ? (
                                              <span>
                                                {" "}
                                                <del>
                                                  {item?.service?.service_id?.original_price}
                                                </del>{" "}
                                              </span>
                                            ) : (
                                              item?.service?.service_id?.original_price
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </h5>
                                        <h6>
                                  <span style={{ fontWeight: 600 }}>
                                    {lang("Provider:")}{" "}
                                  </span>

                                  {language !== "en" && language !== null
                                    ? item?.service?.provider_id?.[
                                        `${language}_name`
                                      ] ?? item?.service?.provider_id?.name
                                    : item?.service?.provider_id?.name}
                                </h6>
                                <p className="mt-2">
                                  <span style={{ fontWeight: 600 }}>
                                    {lang("Description:")}{" "}
                                  </span>

                                  {language !== "en" && language !== null
                                    ? item?.service?.provider_id?.[
                                        `${language}_description`
                                      ] ??
                                      item?.service?.provider_id?.description
                                    : item?.service?.provider_id?.description}
                                </p>
                               
                              </div>
                              <div className="products-like">
                                <div
                                  className="main-fav-b-1"
                                  onClick={(e) =>
                                    {e.stopPropagation();
                                    addToFavorite({
                                      id: item?.service,
                                      type: "Service",
                                    })}
                                  }
                                >
                                  <HeartFilled
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="maain-noevent-imaagee">
                          <Image preview={false} src={NofavService} />
                        </div>
                      )}
                    </div>
                  )}
                </TabPane>

                <TabPane
                  tab={lang("Providers")}
                  key="2"
                  className="budget-main"
                >
                  <ProviderTab
                    loading={loading}
                    data={providers}
                    favourite={(value) => {
                      addToFavorite(value);
                      console.log(value, "values..");
                    }}
                  />
                </TabPane>

                <TabPane tab={lang("Packages")} key="3" className="budget-main">
                  <PackagesTab
                    loading={loading}
                    data={packages}
                    favourite={(value) => {
                      addToFavorite(value);
                      console.log(value, "values..");
                    }}
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
}

const ProviderTab = ({ data, loading, favourite }) => {
  const navigate = useNavigate();
  const { isMobile, language } = useAppContext();
  const navigateProvider = (id) => {
    navigate(`/provider/${id}`);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="faverate-servicess">
          {data.length ? (
            data?.map((item) => (
              <div className="mian-fvrt-services"
              onClick={() => navigateProvider(item?.vendor_id?._id)}
              style={{ cursor: "pointer" }}
              >
                <div
                  className="mian-fvrt-ser-1"
                 
                >
                  <img  src={item?.vendor_id?.logo ? item.vendor_id?.logo : (item?.vendor_id?.selected_category_id?.image ? item.vendor_id?.selected_category_id.image : notfound)} alt="" />
                </div>
                <div className="mian-fvrt-ser-2">
                  <h3 >
                    {" "}
                    {language !== "en" && language !== null
                      ? item?.vendor_id?.[`${language}_name`] ??
                        item?.vendor_id?.name
                      : item?.vendor_id?.name}
                  </h3>
                  <p>
                    {language !== "en" && language !== null
                      ? item?.vendor_id?.[`${language}_description`] ??
                        item?.vendor_id?.description
                      : item?.vendor_id?.description}
                  </p>
                  <h5>
                    <div className="rating-fvrt">
                      <img src={Star4Img} alt="" />
                      {item?.vendor_id?.avg_rating.toFixed(1) ?? 0.0} /{" "}
                      {item?.vendor_id?.review_count ?? 0} reviews
                    </div>
                  </h5>

                  <div className="products-like">
                    <div
                      className="main-fav-b-1"
                      onClick={(e) =>
                        {
                        e.stopPropagation()
                        favourite({
                          id: item?.vendor_id?._id,
                          type: "Provider",
                        })}
                      }
                    >
                      <HeartFilled
                        loading={loading}
                        style={{
                          color: "red",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-event-maainhh providers-no">
              <Image preview={false} src={Nodata} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const PackagesTab = ({ data, loading, favourite }) => {
  const { isMobile, language } = useAppContext();

  const navigate = useNavigate();
  const navigateProvider = (id) => {
    console.log(id, "idddddddddddddddd");
    navigate(`/provider/${id}`);
  };

  console.log(data, "data");

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="faverate-servicess">
          {data.length ? (
            data?.map((item) => (
              <div className="mian-fvrt-services"
              style={{cursor : "pointer"}}
              onClick={() =>
                navigateProvider(item?.packages?.provider_id?._id)
              }
              >
                <div
                  className="mian-fvrt-ser-1"
                
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={item?.packages?.packages_id?.cover_image  ? item?.packages?.packages_id?.cover_image :
                       item?.packages?.provider_id?.logo ? item?.packages?.provider_id?.logo : 
                       item?.packages?.provider_id?.selected_category_id?.image  ?? notfound}
                    alt=""
                  />
                </div>
                <div className="mian-fvrt-ser-2">
                  <h3
                    onClick={() =>
                      navigateProvider(item?.packages?.provider_id?._id)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    {language !== "en" && language !== null
                      ? item?.packages?.packages_id?.[`${language}_name`] ??
                        item?.packages?.packages_id?.name
                      : item?.packages?.packages_id?.name}
                  </h3>
                  <p>
                    {language !== "en" && language !== null
                      ? item?.packages?.provider_id?.[
                          `${language}_description`
                        ] ?? item?.packages?.provider_id?.description
                      : item?.packages?.provider_id?.description}
                  </p>
                  <h5>
                    <div className="rating-fvrt">
                      {/* <img src={Star4Img} alt="" /> */}
                      {/* {item?.vendor_id?.avg_rating ?? 0.0} / {item?.provider_id?.review_count ?? 0} reviews */}
                    </div>
                  </h5>
                  <h6>
                    <span style={{ fontWeight: 600 }}>
                      {lang("Provider:")}{" "}
                    </span>

                    {language !== "en" && language !== null
                      ? item?.packages?.provider_id?.[`${language}_name`] ??
                        item?.packages?.provider_id?.name
                      : item?.packages?.provider_id?.name}
                  </h6>

                  <div className="products-like">
                    <div
                      className="main-fav-b-1"
                      onClick={(e) =>
                       { e.stopPropagation()
                        favourite({ id: item?.packages, type: "Packages" })}
                      }
                    >
                      <HeartFilled
                        loading={loading}
                        style={{
                          color: "red",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-event-maainhh providers-no">
              <Image preview={false} src={Nodata} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar">
      <Link onClick={() => navigate(-1)} className="sidebar-btn">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};

export default Favourite;
