import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Col,
  Row,
  InputNumber,
} from "antd";
import { SelectInput } from "../../components/InputField";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "bootstrap/dist/css/bootstrap.min.css";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import moment from "moment";
import { useAppContext } from "../../context/AppContext";

function AddEvent({ hide, show, loading, refresh, data , budgetData }) {
  const [events, setEvents] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const {language} = useAppContext()


  const [form] = Form.useForm();
  const { request } = useRequest();
  const formData = Form.useWatch([],form)

  console.log(formData,"formData")

  const getEventType = () => {
    request({
      url: apiPath.common.eventType,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setEvents(data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getCountry = () => {
    request({
      url: apiPath.common.country,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setCountries(data?.data);
        }
      },
    });
  };

  const getCity = (id) => {
    request({
      url: apiPath.common.city + id,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setCities(data.data);
        }
      },
    });
  };

  const onSubmit = (values) => {
    const payload = { ...values , };
    request({
      url: data ? `${apiPath.editEvent}/${data?._id}` : apiPath.addEvent,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
           if(budgetData){
            budgetData.addBudgetType === "pkgBudget" ?  pkgAddToBudget(data?.data?._id) : serviceAddToBudget(data?.data?._id)
            console.log(data?.data, "ServiceAddToBudget...")
           }else{
            form.resetFields();
            hide();
            refresh();
           }
         
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };



  const serviceAddToBudget = (event_id) => {
  
    
    let attributesOptions = [];
      attributesOptions = Object.keys(attributes).map((item) => {
        const attribute = attributes[item];
        return {
          name: attribute?.name,
          ar_name: attribute?.ar_name,
          value: attribute?.value,
          ar_value: attribute?.value,
          type: attribute?.type,
          _id: attribute?._id?._id,
        };
      });
    const payload = {
      event_id: event_id,
      provider_id: budgetData?.vendor_id ? budgetData?.vendor_id : null,
      category_id : budgetData?.category_id
    };

    payload.service = {
      service_id: budgetData?.service_id ? budgetData?.service_id : null,
      price : budgetData?.price ? budgetData?.price : null,
      options: attributesOptions,
    };

    //  return  console.log(payload,"payload")

    request({
      url: apiPath.addBudget,
      method: "POST",
      data: payload,
      onSuccess: (data) => {    
        if (data.status) { 
           ShowToast(data.message, Severty.SUCCESS);
           form.resetFields();
           hide();          
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err?.response?.data?.errors?.[0]?.message, Severty.ERROR);
        console.log(err);
      },
    });
  };


  const pkgAddToBudget = (event_id) => {
    const payload = {
      event_id : event_id,
      package_id: budgetData?.package_id ? budgetData?.package_id : null,  
      price : budgetData?.price ? budgetData?.price : null,
      category_id : budgetData?.category_id
    };

    //  return  console.log(payload,"payload")

    request({
      url: apiPath.addPkgBudget,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          form.resetFields();
          hide();          
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err?.response?.data?.errors?.[0]?.message, Severty.ERROR);
        console.log(err);
      },
    });
  };



  useEffect(()=> {
    if(!budgetData) return
    console.log(budgetData,"budgetData...")
    setAttributes(budgetData?.attributes ? budgetData?.attributes : []);
  },[budgetData])



  useEffect(() => {
    getEventType();
    getCountry();
  }, []);

  useEffect(() => {
    if (!data) return;
    console.log(data,"data")
     form.setFieldsValue({
      ...data,
      event_id: data.event_id?._id,
      country_id: data?.country_id?._id,
      city_id: data?.city_id?._id,
      date: data.date ? moment(data?.date) : "",
    });
    getCountry();
    getCity(data?.country_id?._id);
  }, [data]);

  const handleChangeCountry = (id) => {
    if (id) getCity(id);
    form.setFieldsValue({ city_id: "" });
  };

  return (
    <>
      <Modal
        open={show}
        onCancel={hide}
        title="Basic Modal"
        width={749}
        className="noevent-model"
        okText={lang("Submit")}
        cancelText={lang("Back")}
        okButtonProps={{
          form: "create",
          htmlType: "submit",
          loading: loading,
          className: "submit-button", // Custom class for Submit button
        }}
        cancelButtonProps={{
          className: "back-button", // Custom class for Back button
        }}
      >
        <div className="no-active_event">
          <div className="event-backgg-header">
            <h2>{lang("Enter Your Details for Personalized Experience")}</h2>
          </div>
          <div className="noevent-text">
            <Form
              className="noevent-maain-model"
              id="create"
              form={form}
              onFinish={onSubmit}
              layout="vertical"
            >
              <Row gutter={[24, 0]}>
                <Col xl={12} md={12} span={24}>
                  <Form.Item
                    className="username"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: lang("Please enter a name!"),
                      },
                      {
                        max: 50,
                        message: lang(
                          "Name should not contain more then 50 characters!"
                        ),
                      },
                      {
                        min: 2,
                        message: lang(
                          "Name should contain at least 2 characters!"
                        ),
                      },
                    ]}
                    normalize={(value) => value.trimStart()}
                  >
                    <Input placeholder={lang("Enter Event Name")} />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} span={24}>
                  <Form.Item
                    className="username"
                    name="event_id"
                    rules={[
                      {
                        required: true,
                        message: lang("Please select a event!"),
                      },
                    ]}
                  >
                    <Select
                      className="select-inn main-budgut-444"
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={lang("Select Event Type")}
                      showSearch
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    >
                      {events?.map((item) => (
                        <Select.Option
                          key={item._id}
                          label={item.name}
                          value={item._id}
                        >
                         {language !== "en" && language !== null
                                        ? item?.[
                                        `${language}_name`
                                        ] ?? item?.name
                                        : item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} span={24}>
                  <Form.Item
                    className="username "
                    name="date"
                    rules={[
                      {
                        required: true,
                        message: lang("Please select a Date!"),
                      },
                    ]}
                  >
                    <DatePicker
                      className="date-inn"
                      placeholder={lang("Enter Date")}
                      format={"DD-MM-yy"}
                      disabledDate={(current) => {
                        return current && current < moment().startOf('day');
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} span={24}>
                  <Form.Item
                    className="username main-for-67"
                    name="budget"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                         
                       if (!value) {
                            return Promise.reject(new Error(lang("Please enter a budget!"))
                            );
                          }
                        
                          if (value <= 0) {
                            return Promise.reject(
                              new Error(lang("budget price must be a positive number"))
                            );
                          }

                          return Promise.resolve();
                        }
                      })   
                    ]}
                  >
                    <InputNumber
                      className="number-inn p-0"
                      placeholder={lang("Enter Budget (JOD)")}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    className="username"
                    // label={lang("Country")}
                    name="country_id"
                    rules={[
                      {
                        required: true,
                        message: lang("Please select the country!"),
                      },
                    ]}
                  >
                    <Select
                      className="select-inn"
                      filterOption={(input, option) =>
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      autoComplete="off"
                      placeholder={lang("Select Country")}
                      // defaultValue={data?.country_id?._id}
                      showSearch
                      onChange={(value) => handleChangeCountry(value)}
                    >
                      {countries?.map((item) => (
                        <Select.Option
                          key={item._id}
                          label={item.name}
                          value={item._id}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} md={12} className="p-0">
                  <SelectInput
                    className="username number-inn"
                    name="city_id"
                    // label={lang("City")}
                    placeholder={lang("Select City")}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={cities}
                    cover={{ md: 24 }}
                    colProps={{ sm: 24, span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: lang("Please Select the city"),
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AddEvent;
