import React, { useEffect, useState } from "react";
import { Button, Tabs } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import Nonotification2Img from "../../assets/images/no-notification-page-img.png";
import RemamberImg from "../../assets/images/remamber.png";
import MessageImg from "../../assets/images/message-img.png";
import CopyrightImg from "../../assets/images/copyright.png";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import { Severty, ShowToast } from "../../helper/toast";
import Loader from "../../components/Loader";
import moment from "moment";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { m_db, query, getDocs, db } from "../../config/firebase";
import { useAuthContext } from "../../context/AuthContext";
import { formatTimeAgo } from "../../helper/functions";
import { DeleteOutlined } from "@ant-design/icons";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";


function Index() {
  const { request } = useRequest();
  const [notifications, setNotification] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState("");
  const [showAllDelete, setShowAllDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { language ,setRefreshNotification } = useAppContext();


  const fetchNotification = () => {
    setLoading(true);
    request({
      url: `${apiPath.baseURL}/app/notification`,
      method: "GET",
      onSuccess: (data) => {
        if (data.status) {
          setNotification(data.data);
          console.log(data.data, "noti..");
        }
        setLoading(false);
      },
      onError: (err) => {
        console.log(err);
        setLoading(false);
        ShowToast(err, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchNotification();
  }, [refresh]);

  const handleNotiNavigate = (noti) => {
    console.log(noti, "useFor...");

    if (noti?.use_for === "QUOTE") {
      navigate("/quotation");
    } else if (noti?.use_for === "REPORT") {
      navigate(`/provider/${noti?.data?.provider_id}`);
    } else if (noti?.use_for === "RATE") {
      navigate(`/provider-rating/${noti?.data?.provider_id}`);
    } else if (noti?.use_for === "USER_SIGNUP") {
      navigate("/account");
    }else if(noti?.use_for === "TERMS_CONDITION"){
      navigate("/account");
    }

    if (noti?.use_for && !noti?.is_read) {
      request({
        url: `${apiPath.baseURL}/app/notification/read/${noti?._id}`,
        method: "POST",
        onSuccess: ({ data }) => {
          setRefresh((prev) => !prev);
          ShowToast("Notification Read Successfully", Severty.SUCCESS);
          setTimeout(() => {        
            setRefreshNotification(true)
          }, 20);
        },
        onError: (err) => {
          console.log(err);
        },
      });
    }
  };

  const readAllNotifcation = () => {
    request({
      url: `${apiPath.baseURL}/app/notification/read`,
      method: "POST",
      onSuccess: ({ data }) => {
        ShowToast("Notification  Read All Successfully", Severty.SUCCESS);
        setRefresh((prev) => !prev);
        setTimeout(() => {        
          setRefreshNotification(true)
        }, 20);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const deleteNotification = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.baseURL}/app/notification/delete/${selected}`,
      method: "DELETE",
      onSuccess: ({ data, message }) => {
        ShowToast(message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
        setLoading(false);
        setSelected("");
        setShowAllDelete(false);
        setShow(false);
        setTimeout(() => {        
          setRefreshNotification(true)
        }, 20);
      },
      onError: (err) => {
        ShowToast(err?.response?.data?.message, Severty.ERROR);
        console.log(err);
        setLoading(false);
      },
    });
  };


  return (
    <Main>
      <section>
        <div className="container">
      
        <div className="remamber-1 remamber-1-mani">
        <div className="mani-text-notification902main">
          <div className="mani-text-notification902">{lang("Notification")}</div>
          <div className="main-dltall-not-all">
            <Button
            disabled={!notifications.length}
              className="deleteall-main-button"
              onClick={() => {
                setShowAllDelete(true);
              }}
            >
              {lang("Delete All")}
            </Button>
            <Button
            disabled={!notifications.length}
              className="deleteall-main-button"
              onClick={() => {
                readAllNotifcation();
              }}
            >
              {lang("Read All")}
            </Button>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : notifications.length ? (
          notifications?.map((item) => (
            <div
              className={`remamber-main ${item?.is_read ? "Notification-read" : "Notification-unread"}`}
              onClick={() => handleNotiNavigate(item)}
            >
              <div className="remamber-img">
                <img src={item?.image ? item?.image : RemamberImg} alt="" />
              </div>
              <div className="remamber-teext">
                <div className="remamber-txt-2">
                  <h2>  
                   { language !== "en" && language !== null
                                ? item?.[`${language}_title`] ?? item?.title
                                : item?.title }</h2>
                  <p>
                    {item?.created_at ? formatTimeAgo(item?.created_at) : ""}
                  </p>
                </div>
                <div className="remamber-para remamber-para-main9">
                  <div> 
                    { language !== "en" && language !== null
                                ? item?.[`${language}_description`] ?? item?.description
                                : item?.description }</div>
                  <div className="main-delete-out" onClick={(e)=> {
                     e.stopPropagation();
                     setShow(true);
                     setSelected(item?._id);
                     }}>
                    <DeleteOutlined />
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-noti-data">
            <img src={Nonotification2Img} />
          </div>
        )}
      </div>
        </div>
      </section>
      {show && (
        <ConfirmDeleteModal
          title={lang("Delete Notification")}
          subtitle={lang(`Are you sure you want to delete this notification?`)}
          show={show}
          hide={() => {
            setShow(false);
            setSelected("");
          }}
          onOk={(value) => deleteNotification(value)}
        />
      )}
      {showAllDelete && (
        <ConfirmDeleteModal
          title={lang("Delete All Notification")}
          subtitle={lang(`Are you sure you want to delete all notification?`)}
          show={showAllDelete}
          hide={() => {
            setShowAllDelete(false);
            setSelected("");
          }}
          onOk={(value) => {
            setSelected();
            deleteNotification();
          }}
        />
      )}
    </Main>
  );
}




export default Index;
