import { Col } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Wallet from "../assets/images/side_nav/wallet.svg";
import Timeuser from "../assets/images/side_nav/time.svg";
import Notification from "../assets/images/side_nav/basil_notification.svg";
import UserPlus from "../assets/images/side_nav/user-plus.svg";
import Logout from "../assets/images/side_nav/logout-outlined.svg";
import Securityimg from "../assets/images/side_nav/securitymargin.svg";
import Paymentmethod from "../assets/images/side_nav/paymentmethod.svg";
import Autobuy from "../assets/images/side_nav/autobuy.svg";
import UserIcon from "../assets/images/side_nav/user.svg";
import Menu from "../assets/images/menu.png";
import RightIcon from "../assets/images/right-errow.png";
import { AuthContext, useAuthContext } from "../context/AuthContext";
import ConfirmLogoutModal from "./ConfirmLogoutModal";
import UserlogoImg from "../assets/images/profile.png";
import FavrateImg from "../assets/images/faveratee.png";
import CpasswordImg from "../assets/images/Password.png";
import InviteFrndImg from "../assets/images/invtfrnd.png";
import AboutImg from "../assets/images/aboutus.png";
import PolicyImg from "../assets/images/privecy.png";
import RateImg from "../assets/images/rate on store.png";
import DeleteImg from "../assets/images/delete account.png";
import { Container } from "react-bootstrap";
import { ShowToast, Severty } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import OtpModal from "../modals/OtpModal";
import apiPath from "../constants/apiPath";
import lang from "../helper/langHelper";

const AccountSideNav = ({}) => {
  const location = useLocation();
  const { request } = useRequest();
  const [show, setShow] = useState(false);
  const { logout, setUserProfile, setIsLoggedIn, isLoggedIn } =
    useContext(AuthContext);
  const { userProfile } = useAuthContext();
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const navigate = useNavigate();
  const [showOtpModal, setShowOtpModal] = useState(false);

  const showDeleteConfirm = (record) => {
    // setIsLogoutModalVisible(true);
    navigate("/login");
    logout();
  };

  useEffect(() => {
    if (!isLoggedIn) navigate("/login");
  }, []);

  const handleSendOtp = () => {
    // Send the OTP to the user's email
    let payload = {};
    payload.email = payload.email = userProfile?.email
      ? userProfile?.email
      : "";
    payload.use_for = "Login";

    request({
      url: apiPath.sendOtp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  return (
    <>
      <Col span={24} lg={6} xxl={4} className="">
        <div className="side-nav-main">
          <div className="fillter-sidebar">
            <Link className="sidebar-btn" onClick={() => setShow(!show)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  d="M9 19H7V5h2zm4-14h-2v14h2zm4 0h-2v14h2z"
                />
              </svg>
            </Link>
          </div>
          <div className={show ? "sideshow" : "sidehide"}>
            <div className="side-nav">
              <ul>
                <li
                  className={`nav-items ${ location.pathname === "/account" ? "active" : ""}`}
                >
                  <Link to="/account">
                    <img src={UserlogoImg} />
                    {lang("My Profile")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${  location.pathname === "/favourite" ? "active" : ""}`}
                >
                  <Link to="/favourite">
                    <img src={FavrateImg} />
                    {lang("Favourites")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/change-password" ? "active" : ""
                  }`}
                >
                  <Link to="/change-password">
                    <img src={CpasswordImg} />
                    {lang("Change Password")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/invite" ? "active" : ""
                  }`}
                >
                  <Link to="/invite">
                    <img src={InviteFrndImg} />
                    {lang("Invite Friends")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/about-us" ? "active" : ""
                  }`}
                >
                  <Link to="/about-us">
                    <img src={AboutImg} />
                    {lang("About Us")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/privacy-policy" ? "active" : ""
                  }`}
                >
                  <Link to="/privacy-policy">
                    <img src={PolicyImg} />
                    {lang("Privacy Policy")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/rate-store" ? "active" : ""
                  }`}
                >
                  <Link to="/rate-store">
                    <img src={RateImg} />
                    {lang("Rate on Store")}
                  </Link>
                </li>
                {/* <li
                  className={`nav-items ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <Link>
                    <img src={DeleteImg} onClick={() => setDeleteAccount(true)} />
                    Delete Account
                  </Link>
                </li> */}
                <li
                  className={`nav-items${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <Link onClick={() => setDeleteAccount(true)}>
                    <img src={DeleteImg} />
                    {lang("Delete Account")}
                  </Link>
                </li>

                <li
                  className={`nav-items${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <Link onClick={() => setIsLogoutModalVisible(true)}>
                    <img src={Logout} />
                    {lang("Logout")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Col>

      {isLogoutModalVisible && (
        <ConfirmLogoutModal
          reasons={[]}
          title={lang("Logout")}
          subtitle={lang(`Are you sure you want to Logout ?`)}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={() => showDeleteConfirm()}
        />
      )}
      {deleteAccount && (
        <ConfirmLogoutModal
          reasons={[]}
          title={lang("Delete Account")}
          subtitle={lang(`Are you sure you want to delete this Account?`)}
          show={deleteAccount}
          hide={() => {
            setDeleteAccount(false);
          }}
          onOk={() => {
            setShowOtpModal(true);
            handleSendOtp();
          }}
        />
      )}
      {showOtpModal && (
        <OtpModal
          show={showOtpModal}
          hide={() => {
            setShowOtpModal(false);
          }}
        />
      )}
    </>
  );
};

export default AccountSideNav;
