import {
  Button,
  Select,
  Form,
  Row,
  Col,
  Drawer,
  Space,
  Slider,
  Checkbox,
  Radio,
  Input,
  InputNumber,
  DatePicker,
  Divider,
} from "antd";
import FilterbuttonImg from "../assets/images/filterbutton.png";
import { RestOutlined, UndoOutlined } from "@ant-design/icons";
import lang from "../helper/langHelper";
import { useAppContext } from "../context/AppContext";
import { useEffect, useState } from "react";
import { ColorCircleComponent } from "./functions";

const ManageAttributeFilter = ({ attributes, attrPayload , attrFilterData }) => {
  const [showInfoCol, setShowInfoCol] = useState({});
  const [showOptions, setShowOptions] = useState({});
  const { language } = useAppContext();

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  console.log(values, "formData");

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const onFinish = (values) => {
    console.log(values, "values..");
    const filteredValues = [];
    const processedIds = new Set();

    Object.keys(values).forEach((key) => {
      const attribute = attributes?.find((attr) => {
        if (attr?.type !== "package") {
          return attr?._id === key;
        }
        return attr?.options?.some((option) => option?.name === key);
      });

      if (!attribute || processedIds.has(attribute._id)) return;
      processedIds.add(attribute._id);

      let valueToPush = values[key];
      let info = "";
      console.log(attribute, "attribute");

      if (attribute?.type === "calendar") {
        valueToPush = valueToPush ? valueToPush.format("DD-MM-YYYY") : null;
      } else if (attribute?.type === "boolean") {
        console.log(attribute, "attributeboolean");
        valueToPush = valueToPush;
      } else if (attribute?.type === "button") {
        // Button attribute logic - handle checkbox and input
        console.log(showInfoCol[key]?.show, "showInfoCol[key]?.show");
        valueToPush = showInfoCol[key]?.show ? showInfoCol[key]?.show : "";
        info = showInfoCol[key]?.info || "";
      } else if (attribute?.type === "package") {
        const packageOptions = attribute.options
          .map((option) => ({
            option: option.name,
            tags: values[option.name] || [],
          }))
          .filter((opt) => opt.tags.length > 0);

        valueToPush = packageOptions;
      }

      if (attribute?.type === "boolean") {
        if (valueToPush === true || valueToPush === false) {
          filteredValues.push({
            _id: attribute._id,
            value: valueToPush,
            type: attribute?.type,
            // info: info,
          });
        }
      } else {
        if (
          valueToPush !== undefined &&
          valueToPush !== null &&
          valueToPush !== "" &&
          (!Array.isArray(valueToPush) || valueToPush.length > 0)
        ) {
          filteredValues.push({
            _id: attribute._id,
            value: valueToPush,
            type: attribute?.type,
            // info: info,
          });
        }
      }
    });

    attrPayload(filteredValues);
  };

  useEffect(() => {
    if (!attrFilterData) return;
  
    const valuesToSet = {};
    attrFilterData.forEach(attr => {
      valuesToSet[attr._id] = attr.value;
    });
  
    form.setFieldsValue(valuesToSet); // Pre-fill form with the attribute values
    console.log(attrFilterData, "setattrFilterData");
  }, [attrFilterData]);

  const handleInfoCol = (attributeName, value) => {
    console.log(attributeName, "attributeName", value, "value");
    setShowInfoCol((prev) => ({
      ...prev,
      [attributeName]: {
        show: value,
        info: value ? prev[attributeName]?.info || "" : null,
      },
    }));
  };

  const showName = (value) => {
    return language !== "en" && language !== null
      ? value?.[`${language}_name`] ?? value?.name
      : value?.name;
  };

  return (
    <div id="attribute-container">
      <Form
        id="create"
        form={form}
        onChange={() => {
          if (form.isFieldsTouched()) {
            form.submit(); // Submits the form
          }
        }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Col span={24}>
          <h5>{lang("Attributes")}</h5>
        </Col>
        {attributes?.map((attribute) => (
          <>
            <Divider className="categ-dri" />
            <Col span={24} key={attribute?._id}>
              {
                <>
                  {attribute?.type === "radio" && (
                    <Form.Item
                      name={attribute?._id}
                      label={showName(attribute)}
                      valuePropName="checked"
                    >
                      {/* <Radio.Group>
                        {attribute?.options?.map((option) => (
                          <Radio key={option?.name} value={option?.name}>
                            {showName(option)}
                          </Radio>
                        ))}
                      </Radio.Group> */}
                      {attribute?.options?.map((option) => (
                          <Checkbox
                            checked={
                              form.getFieldValue(attribute?._id) ===
                              option?.name
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                form.setFieldValue(
                                  attribute?._id,
                                  option?.name
                                );
                              } else {
                                form.setFieldValue(attribute?._id, null);
                              }
                              form.submit()
                            }
                       
                          }
                           
                          >
                            {showName(option)}
                          </Checkbox>
                        ))}
                    </Form.Item>
                  )}
                  {attribute?.type === "boolean" && (
                    <Form.Item
                      name={attribute?._id}
                      label={showName(attribute)}
                      valuePropName="checked"
                      onChange={() => {
                        form.submit(); 
                      }}
                    >
                      <Checkbox
                        checked={form.getFieldValue(attribute?._id) === true}
                        onChange={(e) => {
                          if (e.target.checked) {
                            form.setFieldValue(attribute?._id, true);
                          } else {
                            form.setFieldValue(attribute?._id, null);
                          }
                        }}
                      >
                        {lang("Yes")}
                      </Checkbox>
                      <Checkbox
                        checked={form.getFieldValue(attribute?._id) === false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            form.setFieldValue(attribute?._id, false);
                          } else {
                            form.setFieldValue(attribute?._id, null);
                          }
                        }}
                      >
                        {lang("No")}
                      </Checkbox>
                    </Form.Item>
                  )}

                  {attribute?.type === "checkbox" && (
                    <Form.Item
                      name={attribute?._id}
                      label={showName(attribute)}
                    >
                      <Checkbox.Group>
                        {attribute?.options.map((option) => (
                          <Checkbox
                            value={option?.name}
                            key={option?.name}
                            label={option?.name}
                          >
                            {showName(option)}
                          </Checkbox>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  )}
                  {attribute?.type === "color" && (
                    <Form.Item
                      name={attribute?._id}
                      label={showName(attribute)}
                    >
                      {/* <Radio.Group className="color-redios">
                        {attribute?.options?.map((option) => (
                          <Radio
                            className="slide-colors"
                            value={option?.name}
                            key={option?.name}
                          >
                            <div className="cls-redios">
                              <p>{showName(option)}</p>
                              <ColorCircleComponent
                                color={option?.name ? option?.name : ""}
                              />
                            </div>
                          </Radio>
                        ))}
                      </Radio.Group> */}
                       {attribute?.options?.map((option) => (
                          <Checkbox
                            checked={
                              form.getFieldValue(attribute?._id) ===
                              option?.name
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                form.setFieldValue(
                                  attribute?._id,
                                  option?.name
                                );
                              } else {
                                form.setFieldValue(attribute?._id, null);
                              }
                              form.submit()
                            }
                       
                          }
                           
                          >
                            <div className="selected-color-main">
                            {showName(option)}  
                            <ColorCircleComponent  color={option?.name ? option?.name : ""}/>
                            </div>
                          </Checkbox>
                        ))}
                      
                    </Form.Item>
                  )}
                  {attribute?.type === "dropdown" && (
                    <Form.Item
                      name={attribute?._id}
                      label={showName(attribute)}
                    >
                      <Select
                        className=" "
                        placeholder={`Select ${showName(attribute)}`}
                        onChange={() => {
                          form.submit(); 
                        }}
                      >
                        {attribute?.options?.map((option) => (
                          <Select.Option
                            value={option?.name}
                            key={option?.name}
                          >
                            {showName(option)}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {attribute?.type === "button" && (
                    <Form.Item className="input-mb-o" name={attribute?._id}>
                      <Checkbox
                        valuePropName="checked"
                        checked={!!showInfoCol[attribute?._id]?.show}
                        onChange={(e) =>
                          handleInfoCol(attribute?._id, e.target.checked)
                        }
                        value={showInfoCol[attribute?._id]?.show}
                      >
                        {showName(attribute)}
                      </Checkbox>

                     
                    </Form.Item>
                  )}
                  {attribute?.type === "inputNumber" && (
                    <Form.Item
                      className="num-inn-se"
                      name={attribute?._id}
                      label={showName(attribute)}
                    >
                      <InputNumber
                        className="number-inputs"
                        placeholder={`Enter ${showName(attribute)}`}
                      />
                    </Form.Item>
                  )}
                  {attribute?.type === "textbox" && (
                    <Form.Item
                      name={attribute?._id}
                      label={showName(attribute)}
                    >
                      <Input placeholder={`Enter ${showName(attribute)}`} />
                    </Form.Item>
                  )}
                  {attribute?.type === "calendar" && (
                    <Form.Item
                      name={attribute?._id}
                      label={showName(attribute)}
                    >
                      <DatePicker format={"DD-MM-yyyy"} />
                    </Form.Item>
                  )}

                  {attribute?.type === "package" && (
                    <div>
                      {attribute?.options?.map((option) => (
                        <Form.Item
                          key={option?._id}
                          label={`${showName(attribute)} (${showName(option)})`}
                          name={option?.name}
                        >
                          <Select
                            mode="tags"
                            style={{ width: "100%" }}
                            placeholder={`Select ${showName(option)}`}
                          >
                            {option?.tags?.map((tag) => (
                              <Select.Option key={tag} value={tag}>
                                {tag}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      ))}
                    </div>
                  )}
                </>
              }
            </Col>
          </>
        ))}
        {/* <Col span={24} className="mt-3 Send-Requestmain-biutton">
            <Button
              form="create"
              type="primary"
              className="submit-button"
              htmlType="submit"
            >
              {lang("Apply")}
            </Button>
          </Col> */}
      </Form>
    </div>
  );
};

export default ManageAttributeFilter;
