import {
  Button,
  Select,
  Form,
  Row,
  Col,
  Drawer,
  Space,
  Slider,
  Checkbox,
  Radio,
  Input,
  InputNumber,
  DatePicker,
  Divider,
} from "antd";

import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Button, Collapse  } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import ShortingImg from "../../assets/images/shortimg.png";
import FilterbuttonImg from "../../assets/images/filterbutton.png";
import InviteFriends from "../../components/InviteFriends";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import BirthdayImg from "../../assets/images/birthday.png";
import NoSubcategory from "../../assets/images/nosubcategory.png";
import NoData from "../../assets/images/no-data.png";
import notfound from "../../assets/images/not_found.png";
import Loader from "../../components/Loader";
import { RestOutlined, UndoOutlined } from "@ant-design/icons";
import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";
import { ColorCircleComponent } from "../../helper/functions";
import CommonFunction from "../../helper/CommonFunction";
import ManageAttributeFilter from "../../helper/ManageAttributeFilter";

const responsiveSettings = {
  0: {
    items: 2,
  },
  400: {
    items: 3,
  },
  600: {
    items: 3,
  },
  700: {
    items: 4,
  },
  800: {
    items: 4,
  },
  1000: {
    items: 4,
  },
  1200: {
    items: 5,
  },
  1400: {
    items: 5,
  },
};

function Index() {
  const navigate = useNavigate();
  const { language } = useAppContext();
  const [scrollPosition, setScrollPosition] = useState(0);
  const { request } = useRequest();
  const params = useParams();
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [filterServices, setFilterServices] = useState([]);
  const [loading, setLoading] = useState([]);
  const commonFunction = CommonFunction();
  const [attributes, setAttributes] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const serviceId = urlParams.get("service_id");
  const subCategoryId = urlParams.get("sub_category_id");
  const categoryId = urlParams.get("category_id");
  const sort = urlParams.get("sort");
  const rate = urlParams.get("rate");
  const keyFilter = urlParams.get("key_filter");
  const price = urlParams.get("price");
  const minPrice = urlParams.get("min_price");
  const maxPrice = urlParams.get("max_price");
  const parsedAttributes = urlParams.getAll("attributes[]")?.map((attr) => {
    return JSON.parse(decodeURIComponent(attr));
  });

  const [filter, setFilter] = useState({
    category_id: params.id ? params.id : undefined,
    sub_category_id: subCategoryId ? subCategoryId : undefined,
    service_id: serviceId ? serviceId : undefined,
    sort: sort ? sort : undefined,
    rate: rate ? rate : undefined,
    key_filter: keyFilter ? keyFilter : "all",
    price: price ? price : undefined,
    min_price: minPrice ? minPrice : undefined,
    max_price: maxPrice ? maxPrice : undefined,
  });
  const [attrFilterData, setAttrFilterData] = useState(parsedAttributes ?? {});
  const [activeIndex, setActiveIndex] = useState();
  const [currentSubId, setCurrentSubId] = useState(
    filter?.sub_category_id  ? filter?.sub_category_id  : null
  );

 

  useEffect(() => {
    if (filter?.sub_category_id && subCategories?.length) {
      const index = subCategories.findIndex((item) => item._id === filter.sub_category_id);
      setActiveIndex(index !== -1 ? index : null);
    }
  }, [filter?.sub_category_id, subCategories]);

  const handleItemClick = (index, id) => {
    setActiveIndex(index);
    handleSubCategoryId(id);
  };

  const handleSubCategoryId = (id) => {
    // console.log(id, "subcategorid");
    getServices(id);
    setCurrentSubId(id);
  };

  const getCategories = () => {
    setLoading(true);

    request({
      url: apiPath.common.categories,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);

        if (status) {
          setCategories(data.data);
       
        }
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
      },
    });
  };

  const getFilterService = (id) => {
    setLoading(true);
    request({
      url: apiPath.common.services + "/" + id,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);

        if (status) {
          setFilterServices(data?.data ?? []);
        }
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
      },
    });
  };

  const getFilterAttributes = (id) => {
    setLoading(true);     
    request({
      url: apiPath.common.filteredAttribute + "/" + params.id + "/" + id,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);

        if (status) {
          setAttributes(data.data);
        }
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
      },
    });
  };

  const getSubCategories = (id) => {
    // setActiveIndex(0)
    setLoading(true);
    request({
      url: `${apiPath.subCategories}/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setSubCategories(data?.docs);    
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
      },
    });
  };

  const getServices = (id) => {
    setScrollPosition(window.scrollY);

    setLoading(true);
    const payload = {};
    payload.attribute_ids = attrFilterData ? attrFilterData : "";
    console.log(payload, "payload");

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v != undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");


    if (attrFilterData) {
      const serializeAttributes = (attributes) => {
        return attributes
          .map(
            (attr) => `attributes[]=${encodeURIComponent(JSON.stringify(attr))}`
          )
          .join("&");
      };

      navigate( `/service/${params.id ?? ""}${queryString ? `?${queryString}` : ""}${attrFilterData ? `&${serializeAttributes(attrFilterData)}` : ""}`  );
    }else{
    
      navigate( `/service/${params.id ?? ""}${queryString ? `?${queryString}` : ""}`);
    }




    request({
      url: `${apiPath.services}${ id ? "/" + id : ""}?search=${""}${
        queryString ? `&${queryString}` : ""
      }`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (!data.status) return;
        setServices(data.docs);
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
      },
    });
  };

  const handleSetCategoryParams = (id) => {
    navigate(`/service/${id}`);
  };

  useEffect(() => {
    setLoading(true);
    handleSubCategoryId(currentSubId);
  }, [filter]);

  useEffect(() => {
    getFilterService(params.id);  //side nav services
    getSubCategories(params.id);  
    setLoading(true);
    getCategories();
    getServices(filter?.sub_category_id)
  }, [params.id, attrFilterData]);

  useEffect(() => {
    if (filter?.service_id) {
      getFilterAttributes(filter?.service_id);
    }
  }, [filter?.service_id]);

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [placement, setPlacement] = useState("left");

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const handlePriceRangeChange = (value) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      min_price: value[0],
      max_price: value[1],
    }));
  };

  const filterCategory = categories?.find(
    (item) => item?._id === filter?.category_id
  );
  console.log(filterCategory, "filterCategory");

  const resetFilter = () => {
    setFilter({
      category_id: params.id ? params.id : undefined,
      sub_category_id: undefined,
      service_id: undefined,
      sort: undefined,
      rate: undefined,
      key_filter: "all",
      price: undefined,
      min_price: undefined,
      max_price: undefined,
    });
    setAttributes([]);
    setAttrFilterData([]);
    setActiveIndex();
  };

  const handleCategoryOnChange = (id) => {
    handleSetCategoryParams(id);
    onChange("category_id", id);
    setFilter((prev) => ({
      ...prev,
      sub_category_id: undefined,
      service_id: undefined,
      sort: undefined,
      rate: undefined,
      key_filter: "all",
      price: undefined,
      min_price: undefined,
      max_price: undefined,
    }));
    setAttributes([]);
    setAttrFilterData([]);
    setActiveIndex();
  };

  const handleNavProvider = (id, serviceId) => {
    let vendor_id = id ? id : "";
    commonFunction.updateProviderViewCount(vendor_id, serviceId);
  };

  useEffect(() => {
    window.scrollTo(0, scrollPosition);
  }, [services]);


  return (
    <Main>
      <>
        <section className="filter-bg">
          <div className="container">
            <Row gutter={24}>
              <Col span={24} md={6} lg={6} className="d-none d-lg-block">
                <div className="panel-group">
                  <div className="panel panel-default main-filter-btn-main-145">
                    <div className="panel-heading main-filter-btn-main-45">
                      <Button
                        // onClick={() => setOpen(!open)}
                        className="float-end filter-button-no-reset"
                      >
                        <div>
                          <img src={FilterbuttonImg} alt="Filter button" />
                          <span>{lang("Filter")}</span>
                        </div>
                        {/* {open && "Filter"} */}
                      </Button>
                      <Button
                        onClick={() => resetFilter()}
                        type="primary"
                        className="filter-button-reset"
                      >
                        <div>
                          <UndoOutlined />
                          <span>{lang("Reset")}</span>
                        </div>
                      </Button>
                    </div>
                    <div>
                      <div className="baccolor">
                        <div className="first-bg">
                        {loading ? <Loader/> :
                        <>
                         <div className="manager">
                            <h6>{lang("Category")}</h6>
                            <Form.Item className="filtter-select">
                             <Select
                                className="form-select"
                                aria-label="Default select example"
                                placeholder={lang("Select Category")}
                                onChange={(e) => {
                                  handleCategoryOnChange(e);
                                }}
                                filterOption={(input, option) =>
                                  option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                }
                                showSearch
                                value={filter.category_id}
                              >
                                {categories?.length &&
                                  categories?.map((item) => (
                                    <Select.Option
                                      value={item._id}
                                      key={item._id}
                                    >
                                      {language !== "en" && language !== null
                                        ? item?.[`${language}_name`] ??
                                          item?.name
                                        : item?.name}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="manager">
                            <h6>{lang("Service")}</h6>
                            <Form.Item className="filtter-select">
                              <Select
                                className="form-select"
                                aria-label="Default select example"
                                placeholder={lang("Select Service")}
                                filterOption={(input, option) =>
                                  option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                }
                                showSearch
                                onChange={(e) => {
                                  getFilterAttributes(e);
                                  onChange("service_id", e);
                                }}
                                value={filter.service_id}
                              >
                                {filterServices?.length &&
                                  filterServices?.map((item) => (
                                    <Select.Option
                                      value={item._id}
                                      key={item._id}
                                    >
                                      {language !== "en" && language !== null
                                        ? item?.[`${language}_name`] ??
                                          item?.name
                                        : item?.name}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </div> </>}

                          {attributes?.length ? (
                            <>
                              <ManageAttributeFilter
                                attributes={attributes}
                                attrPayload={(value) =>
                                  setAttrFilterData(value)
                                }
                                attrFilterData={attrFilterData}
                              />
                            </>
                          ) : (
                            ""
                          )}

                          <div className="manager">
                            <h6>{lang("Price")}</h6>

                            <div>
                              {/* Slider component */}
                              <div className="range-input">
                                <Slider
                                  range
                                  min={0}
                                  max={10000}
                                  step={100}
                                  defaultValue={[
                                    filter.min_price,
                                    filter.max_price,
                                  ]}
                                  value={[filter.min_price, filter.max_price]}
                                  onChange={handlePriceRangeChange}
                                />
                              </div>
                              {/* Display current range values */}
                              <div style={{ marginTop: 20 }}>
                                {lang("Selected Range:")} {filter.min_price} -{" "}
                                {filter.max_price}
                              </div>
                            </div>
                          </div>
                          <div className="manager">
                            <h6>
                              <img src={ShortingImg} alt="" /> {lang("Sort By")}
                            </h6>
                            <div className="radio-container-maain">
                              <label className="radio-container">
                                <input
                                  checked={filter.sort === "A_Z"}
                                  type="radio"
                                  name="radio-option"
                                  onChange={(value) => onChange("sort", "A_Z")}
                                />
                                <span className="radio-checkmark" />
                                <img
                                  src="image/bi_sort-alpha-up.png"
                                  alt=""
                                />{" "}
                                {lang("A to Z")}
                              </label>

                              <label className="radio-container">
                                <input
                                  checked={filter.sort === "Z_A"}
                                  type="radio"
                                  name="radio-option"
                                  onChange={(value) => onChange("sort", "Z_A")}
                                />
                                <span className="radio-checkmark" />
                                <img
                                  src="image/bi_sort-alpha-down.png"
                                  alt=""
                                />{" "}
                                {lang("Z to A")}
                              </label>
                              <label className="radio-container">
                                <input
                                  checked={filter.sort === "most_view"}
                                  type="radio"
                                  name="radio-option"
                                  onChange={(value) =>
                                    onChange("sort", "most_view")
                                  }
                                />
                                <span className="radio-checkmark" />
                                <img
                                  src="image/bi_sort-alpha-down.png"
                                  alt=""
                                />{" "}
                                {lang("Most View")}
                              </label>
                              <label className="radio-container">
                                <input
                                  type="radio"
                                  name="radio-option"
                                  checked={filter.sort === "Price_L_to_H"}
                                  onChange={(value) =>
                                    onChange("sort", "Price_L_to_H")
                                  }
                                />
                                <span className="radio-checkmark" />
                                <img src="image/pricedown.png" alt="" />
                                {lang("Price Low to High")}
                              </label>
                              <label className="radio-container">
                                <input
                                  type="radio"
                                  name="radio-option"
                                  checked={filter.sort === "Price_H_to_L"}
                                  onChange={(value) =>
                                    onChange("sort", "Price_H_to_L")
                                  }
                                />
                                <span className="radio-checkmark" />
                                <img src="image/pricelow.png" alt="" />
                                {lang("Price High to Low")}
                              </label>
                              <label className="radio-container">
                                <input
                                  type="radio"
                                  name="radio-option"
                                  checked={filter.sort === "Rate_L_to_H"}
                                  onChange={(value) =>
                                    onChange("sort", "Rate_L_to_H")
                                  }
                                />
                                <span className="radio-checkmark" />
                                <img src="image/pricedown.png" alt="" />
                                {lang("Rating Low to High")}
                              </label>
                              <label className="radio-container">
                                <input
                                  type="radio"
                                  name="radio-option"
                                  checked={filter.sort === "Rate_H_to_L"}
                                  onChange={(value) =>
                                    onChange("sort", "Rate_H_to_L")
                                  }
                                />
                                <span className="radio-checkmark" />
                                <img src="image/pricelow.png" alt="" />
                                {lang("Rating High to Low")}
                              </label>
                              <label className="radio-container">
                                <input
                                  type="radio"
                                  name="radio-option"
                                  checked={filter.sort === "near"}
                                  onChange={(value) => onChange("sort", "near")}
                                />
                                <span className="radio-checkmark" />
                                <img src="image/pricedown.png" alt="" />{" "}
                                {lang("Nearest (Near By)")}
                              </label>
                              <label className="radio-container">
                                <input
                                  type="radio"
                                  name="radio-option"
                                  checked={filter.sort === "far"}
                                  onChange={(value) => onChange("sort", "far")}
                                />
                                <span className="radio-checkmark" />
                                <img src="image/pricelow.png" alt="" />{" "}
                                {lang("Farthest (Far Away)")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={24} md={24} lg={18}>
                <div className="result-and-shot-div">
                  <div className="for-filterbutton-drowerss d-lg-none d-md-block">
                    <Button type="primary" onClick={showDrawer}>
                      <img src={FilterbuttonImg} alt="Filter button" />
                    </Button>

                    <Drawer
                      title={lang("Filter")}
                      placement={placement}
                      width={300}
                      onClose={onClose}
                      open={isDrawerOpen}
                      extra={
                        <Space>
                          <Button
                            onClick={() => resetFilter()}
                            type="primary"
                            className="filter-button-reset filter-button-reset-44 mt-0"
                          >
                            <UndoOutlined />
                            <span>{lang("Reset")}</span>
                          </Button>
                        </Space>
                      }
                    >
                      <div>
                        <div className="baccolor">
                          <div className="first-bg">
                            <div className="manager">
                              <h6>{lang("Category")}</h6>
                              <Form.Item className="filtter-select">
                                {loading ? <Loader/> :
                                 <Select
                                  className="form-select"
                                  aria-label="Default select example"
                                  placeholder={lang("Select Category")}
                                  filterOption={(input, option) =>
                                    option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                  }
                                  showSearch
                                  onChange={(e) => {
                                    handleCategoryOnChange(e);
                                  }}
                                  value={filter.category_id}
                                >
                                  {categories?.length &&
                                    categories?.map((item) => (
                                      <Select.Option
                                        value={item._id}
                                        key={item._id}
                                      >
                                        {language !== "en" && language !== null
                                          ? item?.[`${language}_name`] ??
                                            item?.name
                                          : item?.name}
                                      </Select.Option>
                                    ))}
                                </Select>}
                              </Form.Item>
                            </div>
                            <div className="manager">
                              <h6>{lang("Service")}</h6>
                              <Form.Item className="filtter-select">
                                <Select
                                  className="form-select"
                                  aria-label="Default select example"
                                  placeholder={lang("Select Service")}
                                  filterOption={(input, option) =>
                                    option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                  }
                                  showSearch
                                  onChange={(e) => {
                                    getFilterAttributes(e);
                                    onChange("service_id", e);
                                  }}
                                  value={filter.service_id}
                                >
                                  {filterServices?.length &&
                                    filterServices?.map((item) => (
                                      <Select.Option
                                        value={item._id}
                                        key={item._id}
                                      >
                                        {language !== "en" && language !== null
                                          ? item?.[`${language}_name`] ??
                                            item?.name
                                          : item?.name}
                                      </Select.Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </div>

                            {attributes?.length ? (
                              <>
                                <ManageAttributeFilter
                                  attributes={attributes}
                                  attrPayload={(value) =>
                                    setAttrFilterData(value)
                                  }
                                  attrFilterData={attrFilterData}
                                />
                              </>
                            ) : (
                              ""
                            )}

                            <div className="manager">
                              <h6>{lang("Price")}</h6>

                              <div>
                                {/* Slider component */}
                                <div className="range-input">
                                  <Slider
                                    range
                                    min={0}
                                    max={10000}
                                    step={100}
                                    defaultValue={[
                                      filter.min_price,
                                      filter.max_price,
                                    ]}
                                    value={[filter.min_price, filter.max_price]}
                                    onChange={handlePriceRangeChange}
                                  />
                                </div>
                                {/* Display current range values */}
                                <div style={{ marginTop: 20 }}>
                                  {lang("Selected Range:")} {filter.min_price} -{" "}
                                  {filter.max_price}
                                </div>
                              </div>
                            </div>
                            <div className="manager">
                              <h6>
                                <img src={ShortingImg} alt="" />{" "}
                                {lang("Sort By")}
                              </h6>
                              <div className="radio-container-maain">
                                <label className="radio-container">
                                  <input
                                    checked={filter.sort === "A_Z"}
                                    type="radio"
                                    name="radio-option"
                                    onChange={(value) =>
                                      onChange("sort", "A_Z")
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                  <img
                                    src="image/bi_sort-alpha-up.png"
                                    alt=""
                                  />{" "}
                                  {lang("A to Z")}
                                </label>

                                <label className="radio-container">
                                  <input
                                    checked={filter.sort === "Z_A"}
                                    type="radio"
                                    name="radio-option"
                                    onChange={(value) =>
                                      onChange("sort", "Z_A")
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                  <img
                                    src="image/bi_sort-alpha-down.png"
                                    alt=""
                                  />{" "}
                                  {lang("Z to A")}
                                </label>
                                <label className="radio-container">
                                  <input
                                    checked={filter.sort === "most_view"}
                                    type="radio"
                                    name="radio-option"
                                    onChange={(value) =>
                                      onChange("sort", "most_view")
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                  <img
                                    src="image/bi_sort-alpha-down.png"
                                    alt=""
                                  />{" "}
                                  {lang("Most View")}
                                </label>
                                <label className="radio-container">
                                  <input
                                    type="radio"
                                    name="radio-option"
                                    checked={filter.sort === "Price_L_to_H"}
                                    onChange={(value) =>
                                      onChange("sort", "Price_L_to_H")
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                  <img src="image/pricedown.png" alt="" />
                                  {lang("Price Low to High")}
                                </label>
                                <label className="radio-container">
                                  <input
                                    type="radio"
                                    name="radio-option"
                                    checked={filter.sort === "Price_H_to_L"}
                                    onChange={(value) =>
                                      onChange("sort", "Price_H_to_L")
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                  <img src="image/pricelow.png" alt="" />
                                  {lang("Price High to Low")}
                                </label>
                                <label className="radio-container">
                                  <input
                                    type="radio"
                                    name="radio-option"
                                    checked={filter.sort === "Rate_L_to_H"}
                                    onChange={(value) =>
                                      onChange("sort", "Rate_L_to_H")
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                  <img src="image/pricedown.png" alt="" />
                                  {lang("Rating Low to High")}
                                </label>
                                <label className="radio-container">
                                  <input
                                    type="radio"
                                    name="radio-option"
                                    checked={filter.sort === "Rate_H_to_L"}
                                    onChange={(value) =>
                                      onChange("sort", "Rate_H_to_L")
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                  <img src="image/pricelow.png" alt="" />
                                  {lang("Rating High to Low")}
                                </label>
                                <label className="radio-container">
                                  <input
                                    type="radio"
                                    name="radio-option"
                                    checked={filter.sort === "near"}
                                    onChange={(value) =>
                                      onChange("sort", "near")
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                  <img src="image/pricedown.png" alt="" />{" "}
                                  {lang("Nearest (Near By)")}
                                </label>
                                <label className="radio-container">
                                  <input
                                    type="radio"
                                    name="radio-option"
                                    checked={filter.sort === "far"}
                                    onChange={(value) =>
                                      onChange("sort", "far")
                                    }
                                  />
                                  <span className="radio-checkmark" />
                                  <img src="image/pricelow.png" alt="" />{" "}
                                  {lang("Farthest (Far Away)")}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                  <div className="main-service-page-55588">
                    <div className="result-msin-divv">
                      <h4>
                        <span>
                          {services.length ?? 0} {lang("Results for")}{" "}
                          {filterCategory
                            ? language !== "en" && language !== null
                              ? filterCategory?.[`${language}_name`] ??
                                filterCategory?.name
                              : filterCategory?.name
                            : lang("Choosen Category")}{" "}
                        </span>
                      </h4>
                    </div>
                    <div className="short-by-div">
                      <Select
                        defaultValue={"all"}
                        value={filter.key_filter}
                        className="button-firsttt"
                        options={[
                          {
                            value: "all",
                            label: lang("All"),
                          },
                          {
                            value: "featured",
                            label: lang("Featured"),
                          },
                        ]}
                        onChange={(value) => onChange("key_filter", value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="catter-maain-divvv">
                  <div className="occasions-main_2 Wedding ">
                    {subCategories?.length ? (
                      <div className="cat-taxxts">
                        <h4>{lang("Sub Categories")}</h4>
                      </div>
                    ) : (
                      ""
                    )}

                    {subCategories?.length ? (
                      <OwlCarousel
                        className="owl-theme"
                        loop={false}
                        margin={27}
                        nav={false}
                        dots={false}
                        responsive={responsiveSettings}
                      >
                        {subCategories?.length &&
                          subCategories?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={`wedding-main category-imgs ${
                                  activeIndex === index ? "active" : ""
                                }`}
                              >
                                <div
                                  className="birthday-new catere"
                                  onClick={() =>{
                                    onChange("sub_category_id", item._id)
                                    handleItemClick(index, item._id)
                                  }
                                  }
                                >
                                  <img
                                    src={item?.image ? item.image : notfound}
                                    alt=""
                                  />
                                  <h4
                                    style={{
                                      textTransform: "capitalize",
                                      textWrap: "pretty",
                                    }}
                                  >
                                    {language !== "en" && language !== null
                                      ? item?.[`${language}_name`] ?? item?.name
                                      : item?.name}
                                  </h4>
                                </div>
                              </div>
                            );
                          })}
                      </OwlCarousel>
                    ) : (
                      ""
                      // <div className="item">
                      //   <div className="wedding-main">
                      //     <div className="nosub-ccc">
                      //       <img src={NoSubcategory} alt="" />
                      //     </div>
                      //   </div>
                      // </div>
                    )}
                  </div>
                </div>

                { services?.length ? (
                  <div className="service-main">
                    <div className="Occasions_text">
                      <div>
                        <h4>{lang("Services")}</h4>
                      </div>
                      <div>
                        {/* {services.length > 12 && <a href="#">{lang("View all")}</a>} */}
                      </div>
                    </div>

                    {loading ? (
                      <Loader />
                    ) : (
                      <div className="service_img_main">
                        {services?.map((service) => (
                          <div className="service-img" key={service?._id}>
                            <div
                              onClick={() =>
                                handleNavProvider(
                                  service?.provider_id?._id,
                                  service?._id
                                )
                              }
                            >
                              {service?.service_id?.is_featured && (
                                <div className="featured-type">
                                  {" "}
                                  <h3>{lang("Featured")}</h3>
                                </div>
                              )}
                              <div className="product-img">
                                {" "}
                                <img
                                  src={
                                    service?.cover_image
                                      ? service.cover_image
                                      : service?.service_id?.image || notfound
                                  }
                                />
                              </div>
                              <div className="service_text">
                                <h2
                                  style={{
                                    textTransform: "capitalize",
                                    textWrap: "pretty",
                                  }}
                                  title={service?.service_id?.name ?? ""}
                                >
                                  {service?.name
                                    ? service?.name
                                    : language !== "en" && language !== null
                                    ? service?.service_id?.[
                                        `${language}_name`
                                      ] ?? service?.service_id?.name
                                    : service?.service_id?.name}
                                </h2>
                                <h4 title={service?.provider_id?.name ?? ""}>
                                  {language !== "en" && language !== null
                                    ? service?.provider_id?.[
                                        `${language}_name`
                                      ] ?? service?.provider_id?.name
                                    : service?.provider_id?.name}
                                </h4>
                                <p>
                                  {/* { language !== "en" && language !== null
                                ? service?.provider_id?.[`${language}_description`] ?? service?.provider_id?.description
                                : service?.provider_id?.description } */}
                                </p>
                                <h3 className="jod-price-disc.">
                                  JOD{" "}
                                  {service?.discount_price ??
                                    service?.discount_price}{" "}
                                  {service?.original_price ? (
                                    service?.discount_price ? (
                                      <span>{service?.original_price} </span>
                                    ) : (
                                      service?.original_price
                                    )
                                  ) : (
                                    ""
                                  )}
                                </h3>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                   loading ? <Loader/> :
                   <div className="item">
                    <div className="wedding-main">
                      <div className="nosub-ccc">
                        <img src={NoData} alt="" />
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </section>
        {/* <InviteFriends /> */}
      </>
    </Main>
  );
}

export default Index;
