import moment from "moment";
import lang from "./langHelper";
import { Severty, ShowToast } from "./toast";
import { Image, Row, Col } from "antd";
import { useAppContext } from "../context/AppContext";
import RightcheckImg from "../assets/images/wirghtclick.png";

export const getFileExtension = (url) => {
  // Get the last part of the URL after the last '/'
  const filename = url.substring(url.lastIndexOf("/") + 1);

  // Get the file extension by getting the last part of the filename after the last '.'
  const extension = filename.substring(filename.lastIndexOf(".") + 1);

  return extension;
};

export const isObjectEmpty = (obj) => {
  for (const key in obj) {
    if (obj[key]) {
      if (obj[key] === '{"min":0,"max":20000000}') {
      } else {
        return false;
      }
    }
  }
  return true;
};

export const formatTimeAgo = (date) => {
  const now = moment();
  const postTime = moment(date);
  const diff = now.diff(postTime, lang("minutes"));

  if (diff < 1) return lang("Just now");
  if (diff < 60)
    return `${diff} ${diff === 1 ? lang("minute") : lang("minutes")} ${lang(
      "ago"
    )}`;
  if (diff < 24 * 60)
    return `${Math.floor(diff / 60)} ${
      Math.floor(diff / 60) === 1 ? lang("hour") : lang("hours")
    } ${lang("ago")}`;
  if (diff < 30 * 24 * 60)
    return `${Math.floor(diff / (24 * 60))} ${
      Math.floor(diff / (24 * 60)) === 1 ? lang("day") : lang("days")
    } ${lang("ago")}`;
  return postTime.format("ll");
};

export const formatStringDate = (dateString) => {
  const today = moment().startOf("day");
  const date = moment(dateString).startOf("day");
  const diffDays = today.diff(date, "days");

  if (diffDays === 0) return lang("Today");
  if (diffDays === 1) return lang("Yesterday");
  if (diffDays <= 30) return `${diffDays} ${lang("days ago")}`;
  return date.format("MMM D, YYYY"); // e.g., "Jul 19, 2024"
};

export const ColorCircleComponent = ({ color }) => {
  console.log(color, "colordata");
  const circleStyle = {
    backgroundColor: color,
  };

  return <span style={circleStyle} className="color-selected"></span>;
};

export const showPosition = (position) => {
  const latitude = position.coords.latitude;
  const longitude = position.coords.longitude;
  console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  return { latitude, longitude };
};

export const showError = (error) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      // ShowToast("User denied the request for Geolocation.", Severty.ERROR);
      break;
    case error.POSITION_UNAVAILABLE:
      // ShowToast("Location information is unavailable.", Severty.ERROR);
      break;
    case error.TIMEOUT:
      // ShowToast("The request to get user location timed out.", Severty.ERROR);
      break;
    case error.UNKNOWN_ERROR:
      // ShowToast("An unknown error occurred.", Severty.ERROR);
      break;
  }
};

export const PkgAttrShow = ({ quotations }) => {
  const { language } = useAppContext();

  const showName = (value) => {
    return language !== "en" && language !== null
      ? value?.[`${language}_name`] ?? value?.name
      : value?.name;
  };

  const showAttrValue = (item) => {
    return language !== "en" && language !== null
      ? item?.[`${language}_value`] ?? item?.value
      : item?.value;
  };

  return (
    <div className="for-quotation-background-3">
      {Array.isArray(quotations?.pkg_services) &&
      quotations?.pkg_services?.length > 0 ? (
        quotations?.pkg_services?.map((service, serviceIndex) => (
          <div key={serviceIndex}>
            <h4 className="main-new-header-class-for-all">
              <div className="pkg-src-show">
                <span className="pkg-src-name-show">
                  {showName(service?.pkg_service_id)}
                </span>
                <p>JOD {service?.price ?? 0}</p>
              </div>
            </h4>
            <ol className="main-oll">
              <Row gutter={[12, 12]}>
                {Array.isArray(service?.options) && service?.options?.length ? (
                  service?.options
                    ?.filter((attribute) => {
                      if (attribute?.type === "button") {
                        return attribute?.value === true;
                      } else if (
                        [
                          "radio",
                          "textBox",
                          "inputNumber",
                          "boolean",
                          "file",
                          "color",
                          "package",
                        ].includes(attribute?.type)
                      ) {
                        return attribute?.value;
                      } else if (typeof attribute?.value === "object") {
                        return Object.keys(attribute?.value || {}).length > 0;
                      } else if (Array.isArray(attribute?.value)) {
                        return attribute?.value.length > 0;
                      }
                      return false;
                    })
                    ?.map((attribute, index) => (
                      <Col span={24} md={12} xxl={8}>
                        <li key={index}>
                          {attribute?.type === "button" ? (
                            attribute?.value && attribute?.value === true ? (
                              `${showName(attribute)} : ${attribute.info}`
                            ) : (
                              ""
                            )
                          ) : attribute?.type === "radio" ? (
                            attribute?.value && attribute?.value ? (
                              `${showName(attribute)} : ${showAttrValue(
                                attribute
                              )}`
                            ) : (
                              ""
                            )
                          ) : attribute?.type === "textBox" ? (
                            attribute?.value && attribute?.value ? (
                              `${showName(attribute)} : ${attribute?.value}`
                            ) : (
                              ""
                            )
                          ) : attribute?.type === "inputNumber" ? (
                            attribute?.value && attribute?.value ? (
                              `${showName(attribute)} : ${attribute?.value}`
                            ) : (
                              ""
                            )
                          ) : attribute?.type === "boolean" ? (
                            attribute?.value && attribute?.value === true ? (
                              `${showName(attribute)}`
                            ) : (
                              ""
                            )
                          ) : attribute.type === "file" ? (
                            attribute?.value && attribute?.name ? (
                              `${showName(attribute)} : ${(
                                <Image src={attribute?.value} />
                              )}`
                            ) : (
                              ""
                            )
                          ) : attribute.type === "color" ? (
                            attribute?.value && attribute?.name ? (
                              <div className="selected-color-main">
                                {showName(attribute)} : {attribute?.value}
                                <ColorCircleComponent
                                  color={
                                    attribute?.value ? attribute?.value : ""
                                  }
                                />
                              </div>
                            ) : (
                              ""
                            )
                          ) : attribute.type === "package" ? (
                            <>
                              {attribute?.value?.length
                                ? attribute?.value
                                    ?.map(
                                      (item) =>
                                        `${item?.option} ${
                                          item?.tags?.length
                                            ? `(${item?.tags
                                                ?.map((item) => item)
                                                .join(", ")})`
                                            : ""
                                        }`
                                    )
                                    .join(", ")
                                : ""}{" "}
                            </>
                          ) : typeof attribute?.value === "object" &&
                            !Array.isArray(attribute.value) ? (
                            <ul>
                              {Object.entries(attribute.value).map(
                                ([subKey, subValue], subIndex) => (
                                  <li key={subIndex}>
                                    {subKey}:{" "}
                                    {typeof subValue === "object" &&
                                    !Array.isArray(subValue) ? (
                                      <ul>
                                        {Object.entries(subValue)?.map(
                                          (
                                            [nestedKey, nestedValue],
                                            nestedIndex
                                          ) => (
                                            <li key={nestedIndex}>
                                              {nestedKey} : {nestedValue}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    ) : Array.isArray(subValue) ? (
                                      <ul>
                                        {subValue?.map((item, arrayIndex) => (
                                          <li key={arrayIndex}>{item}</li>
                                        ))}{" "}
                                      </ul>
                                    ) : (
                                      subValue?.toString()
                                    )}
                                  </li>
                                )
                              )}
                            </ul>
                          ) : Array.isArray(attribute?.value) &&
                            attribute?.value?.length ? (
                            <>
                              {showName(attribute)}
                              <ul>
                                {showAttrValue(attribute)?.map(
                                  (item, arrayIndex) => (
                                    <li key={arrayIndex}>{item}</li>
                                  )
                                )}
                              </ul>
                            </>
                          ) : attribute?.name && attribute?.value === true ? (
                            `${showName(attribute)}`
                          ) : (
                            ""
                          )}
                        </li>
                      </Col>
                    ))
                ) : (
                  <p>{lang("")}</p>
                )}
              </Row>
            </ol>
          </div>
        ))
      ) : (
        <p className="no-quotation-3">{lang("")}</p>
      )}
    </div>
  );
};

//View Quotation
export const ServiceAttrShow = ({ quotations }) => {
  const { language } = useAppContext();

  const categorizedValues = Array.isArray(quotations?.values)
    ? quotations?.values?.reduce((acc, attribute) => {
        if (!acc[attribute.type]) {
          acc[attribute.type] = [];
        }
        acc[attribute.type].push(attribute);
        return acc;
      }, {})
    : {};

  const showName = (value) => {
    return language !== "en" && language !== null
      ? value?.[`${language}_name`] ?? value?.name
      : value?.name;
  };

  const showAttrValue = (item) => {
    return language !== "en" && language !== null
      ? item?.[`${language}_value`] ?? item?.value
      : item?.value;
  };

  return (
    <div className="for-quotation-background-3">
      {
        Object.keys(categorizedValues)?.length > 0
          ? Object.keys(categorizedValues)?.map((type) => (
              <div key={type}>
                <ol className="main-oll">
                  <Row gutter={[12, 12]}>
                    {categorizedValues[type]
                      ?.filter((attribute) => {
                        if (attribute?.type === "button") {
                          return attribute?.value === true;
                        } else if (
                          [
                            "radio",
                            "textBox",
                            "inputNumber",
                            "boolean",
                            "file",
                            "color",
                            "package",
                          ].includes(attribute?.type)
                        ) {
                          return attribute?.value;
                        } else if (typeof attribute?.value === "object") {
                          return Object.keys(attribute?.value || {}).length > 0;
                        } else if (Array.isArray(attribute?.value)) {
                          return attribute?.value.length > 0;
                        }
                        return false;
                      })

                      ?.map((attribute, index) => {
                        return (
                          <Col span={24} md={12} xxl={8}>
                            <li key={index}>
                              {attribute?.type === "button" ? (
                                attribute?.value &&
                                attribute?.value === true ? (
                                  `${showName(attribute)} : ${attribute.info}`
                                ) : (
                                  ""
                                )
                              ) : attribute?.type === "radio" ||
                                attribute?.type === "textBox" ||
                                attribute?.type === "inputNumber" ? (
                                attribute?.value ? (
                                  `${showName(attribute)} : ${showAttrValue(
                                    attribute
                                  )}`
                                ) : (
                                  ""
                                )
                              ) : attribute?.type === "boolean" ? (
                                attribute?.value &&
                                attribute?.value === true ? (
                                  `${showName(attribute)}`
                                ) : (
                                  ""
                                )
                              ) : attribute.type === "file" ? (
                                attribute?.value && attribute?.name ? (
                                  `${showName(attribute)} : ${(
                                    <Image src={attribute?.value} />
                                  )}`
                                ) : (
                                  ""
                                )
                              ) : attribute.type === "color" ? (
                                attribute?.value && attribute?.name ? (
                                  <div className="selected-color-main">
                                    {showName(attribute)} : {attribute?.value}
                                    <ColorCircleComponent
                                      color={
                                        attribute?.value ? attribute?.value : ""
                                      }
                                    />
                                  </div>
                                ) : (
                                  ""
                                )
                              ) : attribute.type === "package" ? (
                                <>
                                  {attribute?.value?.length
                                    ? attribute?.value
                                        ?.map(
                                          (item) =>
                                            `${item?.option} ${
                                              item?.tags?.length
                                                ? `(${item?.tags
                                                    ?.map((item) => item)
                                                    .join(", ")})`
                                                : ""
                                            }`
                                        )
                                        .join(", ")
                                    : ""}{" "}
                                </>
                              ) : typeof attribute?.value === "object" &&
                                !Array.isArray(attribute.value) ? (
                                <ul>
                                  {Object.entries(attribute.value).map(
                                    ([subKey, subValue], subIndex) => (
                                      <li key={subIndex}>
                                        {subKey}:{" "}
                                        {typeof subValue === "object" &&
                                        !Array.isArray(subValue) ? (
                                          <ul>
                                            {Object.entries(subValue)?.map(
                                              (
                                                [nestedKey, nestedValue],
                                                nestedIndex
                                              ) => (
                                                <li key={nestedIndex}>
                                                  {nestedKey} : {nestedValue}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        ) : Array.isArray(subValue) ? (
                                          <ul>
                                            {subValue?.map(
                                              (item, arrayIndex) => (
                                                <li key={arrayIndex}>{item}</li>
                                              )
                                            )}{" "}
                                          </ul>
                                        ) : (
                                          subValue?.toString()
                                        )}
                                      </li>
                                    )
                                  )}
                                </ul>
                              ) : Array.isArray(attribute?.value) &&
                                attribute?.value?.length ? (
                                <>
                                  {showName(attribute)}
                                  <ul>
                                    {showAttrValue(attribute)?.map(
                                      (item, arrayIndex) => (
                                        <li key={arrayIndex}>{item}</li>
                                      )
                                    )}
                                  </ul>
                                </>
                              ) : attribute?.name && attribute?.value ? (
                                `${showName(attribute)}`
                              ) : (
                                ""
                              )}
                            </li>
                          </Col>
                        );
                      })}
                  </Row>
                </ol>
              </div>
            ))
          : ""
        // <p className="no-quotation-3">{lang("No values to display.")}</p>
      }
    </div>
  );
};

// Create Quote
export const handleSubmitServiceAttr = (
  attributes,
  showInfoCol,
  serviceAttributes
) => {
  let mappedAttributes = [];
  return (mappedAttributes = Object.keys(attributes)?.reduce((acc, key) => {
    let attribute = serviceAttributes?.find((attr) => {
      if (attr?._id?.type !== "package") {
        return attr?._id?.name === key;
      }
      return attr?._id?.options?.some((option) => option?.name === key);
    });

    const value = attributes[key];

    let ar_value;
    if (Array.isArray(value)) {
      ar_value = value.map((val) => {
        const option = attribute?._id?.options?.find(
          (item) => item?.name === val
        );
        return option?.ar_name || val;
      });
    } else {
      ar_value =
        attribute?._id?.options?.find((item) => item?.name === value)
          ?.ar_name || value;
    }

    if (!attribute) {
      return acc;
    }

    if (
      attribute?._id?.type === "package" &&
      acc?.some((item) => item.name === attribute?._id?.name)
    ) {
      return acc;
    }

    if (attribute?._id?.type === "button") {
      acc.push({
        name: key,
        ar_name: attribute?._id?.ar_name,
        value: showInfoCol[key]?.show ? showInfoCol[key]?.show : null,
        ar_value: showInfoCol[key]?.show ? showInfoCol[key]?.show : null,
        type: attribute?._id?.type,
        _id: attribute?._id?._id,
        info: showInfoCol[key]?.info || "",
      });
    } else if (attribute?._id?.type === "file") {
      acc.push({
        name: key,
        ar_name: attribute?._id?.ar_name,
        type: attribute?.type,
        _id: attribute?._id?._id,
        value: value?.length ? value?.[0]?.url : "",
        ar_value: value?.length ? value?.[0]?.url : "",
      });
    } else if (attribute?._id?.type === "calendar") {
      acc.push({
        name: key,
        ar_name: attribute?._id?.ar_name,
        value: value ? moment(value).format("DD-MM-YYYY") : value,
        ar_value: value ? moment(value).format("DD-MM-YYYY") : value,
        type: attribute?._id?.type,
        _id: attribute?._id?._id,
      });
    } else if (attribute?._id?.type === "package") {
      const packageOptions = attribute?._id?.options?.map((option) => ({
        option: option?.name,
        tags: attributes[option?.name] || [],
      }));

      acc.push({
        name: attribute?.name,
        ar_name: attribute?._id?.ar_name,
        value: packageOptions,
        ar_value: packageOptions,
        type: attribute?._id?.type,
        _id: attribute?._id?._id,
      });
    } else if (attribute?._id?.type === "color") {
      acc.push({
        name: attribute?._id?.name,
        ar_name: attribute?._id?.ar_name,
        value: value?.length ? value : null,
        ar_value: value?.length ? ar_value : null,
        type: attribute?._id?.type,
        _id: attribute?._id?._id,
      });
    } else if (attribute?._id?.type === "radio") {
      acc.push({
        name: attribute?._id?.name,
        ar_name: attribute?._id?.ar_name,
        value: value?.length ? value : null,
        ar_value: value?.length ? ar_value : null,
        type: attribute?._id?.type,
        _id: attribute?._id?._id,
      });
    } else {
      acc.push({
        name: key,
        ar_name: attribute?._id?.ar_name,
        value: value,
        ar_value: ar_value ?? value,
        type: attribute?._id?.type,
        _id: attribute?._id?._id,
      });
    }

    return acc;
  }, []));
};

export const BudgetListAttrShow = ({attributes}) => {
  const { language } = useAppContext();
  const showName = (value) => {
    return language !== "en" && language !== null
      ? value?.[`${language}_name`] ?? value?.name
      : value?.name;
  };

  const showAttrValue = (item) => {
    return language !== "en" && language !== null
      ? item?.[`${language}_value`] ?? item?.value
      : item?.value;
  };

  return (
    <>
      <Row gutter={[12, 12]}>
        {attributes
          ?.filter(
            (attribute, index, self) =>
              attribute?._id !== null &&
              attribute?.type !== null &&
              attribute?.value !== null &&
              attribute?.value !== false &&
              attribute?.value !== undefined &&
              attribute?.value !== "" &&
              !(
                Array.isArray(attribute?.value) &&
                attribute?.value.length &&  
                attribute?.value[0] === false
              ) &&
              index === self.findIndex((a) => a._id === attribute?._id)
          )
          ?.map((attribute) => {
            let shouldRender = false;
            let content = null;
            if (
              attribute?.type === "button" &&
              Array.isArray(attribute?.value) &&
              attribute?.value[0] === true
            ) {
              shouldRender = true;
              content = (
                <>
                  <img src={RightcheckImg} alt="" />{" "}
                  <span>
                    {showName(attribute)}{" "}
                    {attribute?.info ? `(${attribute?.info})` : ""}
                  </span>
                </>
              );
            }

            // Handle "textbox" type
            else if (
              attribute?.type === "textbox" &&
              Array.isArray(attribute?.value) &&
              attribute?.value.length
            ) {
              shouldRender = true;
              content = (
                <>
                  <img src={RightcheckImg} alt="" /> {" "}
                  <span>
                    {showName(attribute)}{" "}
                    {attribute?.value?.length ? `(${attribute?.value[0]})` : ""}
                  </span>
                </>
              );
            }

            // Handle "boolean" type
            else if (
              attribute?.type === "boolean" &&
              Array.isArray(attribute?.value) &&
              attribute?.value.length &&
              attribute?.value[0] === true
            ) {
              shouldRender = true;
              content = (
                <>
                  <img src={RightcheckImg} alt="" /> {" "}
                  <span>{showName(attribute)}</span>
                </>
              );
            }

            // Handle "file" or "package" types
            else if (
              attribute?.type === "file" ||
              attribute?.type === "package"
            ) {
              shouldRender = true;
              content = (
                <>
                  <img src={RightcheckImg} alt="" />{" "}
                  {showName(attribute)}
                </>
              );
            }

            // Handle other types with values
            else if (Array.isArray(attribute?.value) && attribute?.value?.length) {
              shouldRender = true;
              content = (
                <>
                  <img src={RightcheckImg} alt="" />{" "}
                  {showName(attribute)}{" "}
                  {Array.isArray(attribute?.value) &&
                    `(${showAttrValue(attribute).join(", ")})`}
                </>
              );
            }else if (!Array.isArray(attribute?.value)  && attribute?.value !== false && attribute?.value !== null && attribute?.value !== "" && attribute?.value !== undefined ) {
              shouldRender = true;
              content = (
                <>
                <img src={RightcheckImg} alt="" />{" "}
                  {showName(attribute)}{" "}
                  {attribute?.value === true ? "" : `(${showAttrValue(attribute)})`}
                </>
              );
            }

            // Only render the <li> if there's valid content
            return shouldRender ? (
              <Col span={24} md={12} xxl={8} key={attribute?._id?._id}>
                <li>
                  <div className="provider-list-name">{content}</div>
                </li>
              </Col>
            ) : null;
          })}
      </Row>
    </>
  );
};
