import React, { useState, useEffect, useRef } from "react";
import { Tabs, Modal, Button } from "antd";
import OwlCarousel from "react-owl-carousel3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Carousel from "react-bootstrap/Carousel";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import EventlocaImg from "../../assets/images/location-20-regular.png";
import AddImg from "../../assets/images/+.png";
import AddEvent from "./AddEvent";
import useRequest from "../../hooks/useRequest";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import moment from "moment";
import DeletebuttonImg from "../../assets/images/delete-button.png";
import Loader from "../../components/Loader";
import ConfirmModal from "../../components/ConfirmDeleteModal";
import { Severty, ShowToast } from "../../helper/toast";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";

function EventList() {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState("");
  const { language } = useAppContext()
  const { request } = useRequest();
  const navigate = useNavigate();

  const getEvents = () => {
    setLoading(true);
    request({
      url: apiPath.eventList,
      method: "GET",
      onSuccess: ({ data }) => {
        setLoading(false);
        setEvents(data.data);
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  const deleteEvent = () => {
    setLoading(true);
    const id = selected;
    console.log(id, "id");
    request({
      url: `${apiPath.deleteEvent}/${id}`,
      method: "delete",
      onSuccess: ({ data, status, message }) => {
        setLoading(false);
        if (status) {
          ShowToast(message, Severty.SUCCESS);
          setSelected("");
          setConfirmDelete(false);
          setRefresh((prev) => !prev);
        } else {
          ShowToast(message, Severty.ERROR);
        }
      },
      onError: (err) => {
        setLoading(false);
        ShowToast(err, Severty.ERROR);
        console.log(err);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getEvents();
  }, [refresh]);

  console.log(events, "events")

  return (
    <Main>
      <section>
        <div className="container ">
          <div className="event-list-main">
            <div className="event-list-hdr">
              <h3>{lang("My Event List")}</h3>
              {events?.length && events?.length > 0 ? (
                <div className="event-list-budget">
                  <>
                    {loading ? (
                      <Loader />
                    ) : (
                      events?.map((item) => (
                        <div className="event-list-budget-dis">
                          <div
                            className="both-m-div"
                            onClick={() =>
                              navigate(`/event-budget/${item?._id}`)
                            }
                          >
                            <div
                              className="event-type-main"
                            >
                              <div className="event-type">
                                {item?.isShared && (
                                  <div className="featured-type featured-type-main-hfuashf">
                                    <h3>{lang("Shared")}</h3>
                                  </div>
                                )}
                                <p>
   
                                    {language !== "en" && language !== null ? item?.event_id?.[`${language}_name`] ??   item?.event_id?.name : item?.event_id?.name}
                                </p>
                                <h3>
                                  {language !== "en" && language !== null
                                    ? item?.[`${language}_name`] ?? item?.name
                                    : item?.name}
                                </h3>
                              </div>
                              {item?.date ? (
                                <div className="event-type-date">
                                  <p>{lang("Date")}</p>
                                  <h3>
                                    {moment(item?.date).format("DD/MM/yy")}
                                  </h3>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="event-location">
                              <div className="loaction-foe-all-67">
                                <img src={EventlocaImg} alt="" />
                                <h2>
                                  {" "}
                                  {item?.city_id
                                    ? `${item?.city_id?.name} , `
                                    : ""}
                                  {item?.country_id
                                    ? item?.country_id?.name
                                    : ""}
                                </h2>
                              </div>
                             {!item?.isShared ? <div className="edit-and-dlt-bttn">
                                <Button
                                  className="dleltebutton-image-22"
                                  onClick={(e) => {
                                    setShow(true);
                                    setSelected(item);
                                    e.stopPropagation()
                                  }}
                                >
                                  <EditOutlined />
                                </Button>
                                <Button
                                  onClick={(e) => {
                                    setConfirmDelete(true);
                                    setSelected(item?._id);
                                    e.stopPropagation()

                                  }}
                                  className="dleltebutton-image-22"
                                >
                                  {/* <img src={DeletebuttonImg} alt="" /> */}
                                  <DeleteOutlined />
                                </Button>
                              </div>  : "" }
                            </div>
                              
                          </div>
                          <div className="budget-and-guest">
                            <div className="budget-oo">
                              <h2>
                                {lang("Budget:")} <span>JOD {item?.budget ?? 0}</span>{" "}
                              </h2>
                            </div>
                            <div className="bud-guest">
                              <h2>
                                {lang("Guest:")} <span>{item?.guestCount ?? 0}</span>
                              </h2>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </>

                  <div className="add-sign-new" onClick={() => setShow(true)}>
                    <img src={AddImg} alt="" />
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => setShow(true)}
                  className="main-add-new-event-bttnn "
                >
                  <Button>
                    <h4>
                      {" "}
                      <PlusCircleFilled />{" "}
                    </h4>
                    <p>{lang("Add New Event")}</p>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {show && (
        <AddEvent
          show={show}
          hide={() => {
            setShow(false);
            setSelected("");
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {confirmDelete && (
        <ConfirmModal
          show={confirmDelete}
          hide={() => {
            setConfirmDelete(false);
            setSelected("");
          }}
          title={lang("Delete My Event")}
          subtitle={lang(`Are you sure you want to delete this Event?`)}
          refresh={() => setRefresh((prev) => !prev)}
          onOk={() => deleteEvent()}
        />
      )}
    </Main>
  );
}

export default EventList;
